import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, renderList as _renderList, createSlots as _createSlots, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "comp-table" }
const _hoisted_2 = {
  class: "toolbar",
  id: "toolbar",
  ref: "toolbar"
}
const _hoisted_3 = { class: "title-box" }
const _hoisted_4 = { class: "tool-box" }
const _hoisted_5 = { class: "button-box" }
const _hoisted_6 = { class: "content-box" }
const _hoisted_7 = { class: "search-box" }
const _hoisted_8 = {
  key: 1,
  class: "table-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Alert = _resolveComponent("Alert")!
  const _component_elem_table = _resolveComponent("elem-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          style: {"padding":"0 6px","font-size":"16px","white-space":"nowrap"},
          ref: "title"
        }, _toDisplayString($props.title), 513)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "operate"),
          (!!$props.formComponent && $props.isCreate)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                icon: "ios-add",
                type: "primary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.openForm()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("新增")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "custom")
      ])
    ], 512),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_Form, {
          ref: "search_form",
          model: $data.search_data,
          "label-colon": true,
          inline: true,
          class: "search"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "search", { search: $data.search_data })
          ]),
          _: 3
        }, 8, ["model"]),
        ($data.isSearch && $props.searchBtn)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              class: "btn",
              type: "primary",
              icon: "ios-search",
              onClick: $options.onSearch
            }, {
              default: _withCtx(() => [
                _createTextVNode("查询")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true),
        ($data.isSearch && $props.searchBtn)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              class: "btn",
              type: "success",
              icon: "ios-refresh",
              onClick: $options.onReset
            }, {
              default: _withCtx(() => [
                _createTextVNode("重置")
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      ($data.error_msg)
        ? (_openBlock(), _createBlock(_component_Alert, {
            key: 0,
            class: "error-box",
            type: "error",
            "show-icon": ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($data.error_msg), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($data.loaded)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createVNode(_component_elem_table, _mergeProps({ ref: "elem_table" }, _ctx.$props, {
              entity: $props.entity,
              title: $props.title,
              height: "100%",
              isIndex: true,
              onOnChangeSelection: $options.onChangeSelection,
              onOnLoad: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-load', { tag: 'CompTable', value: $event.value })))
            }), _createSlots({ _: 2 }, [
              _renderList($data.slots, (item) => {
                return {
                  name: item,
                  fn: _withCtx((params) => [
                    _renderSlot(_ctx.$slots, item, {
                      row: params.row,
                      col: params.col,
                      index: params.index,
                      event: { delete: $options.onDelete }
                    })
                  ])
                }
              })
            ]), 1040, ["entity", "title", "onOnChangeSelection"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}