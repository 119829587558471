
import Component, { ComponentMethods } from "@/module/component/component"
import CKEditor from "@ckeditor/ckeditor5-build-decoupled-document"

import elemInputObject from "./elem-input-object.vue"
import Utils from "@/module/utils/utils"

class ElemRichTextComponent extends ComponentMethods implements ComponentEntity {
    data() {
        return {
            id: Utils.getUuid(),
        }
    }

    props = {
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
    }

    components = {
        elemInputObject,
    }

    async mounted() {
        this.initCKEditor()
    }

    methods = {
        getTextData() {
            return this.editor.getData()
        },

        initCKEditor() {
            CKEditor.create(document.getElementById("editor-" + this.id), {
                language: "zh-cn",
                ckfinder: {
                    uploadUrl: "/WebAPI/Resource/Image/Ckeditor",
                },
            })
                .then(editor => {
                    const toolbarContainer = document.querySelector("#toolbar-container-" + this.id)
                    toolbarContainer.appendChild(editor.ui.view.toolbar.element)
                    this.editor = editor
                    this.value && editor.setData(this.value)
                })
                .catch(error => {
                    console.error(error)
                    // Message.error("富文本编辑器加载失败！")
                })
        },
    }
}

export default Component.build(new ElemRichTextComponent())
