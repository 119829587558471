import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "elem-password-box",
  "dark-class": "elem-password-box-dark"
}
const _hoisted_2 = ["maxlength", "type", "title", "required", "name", "placeholder"]
const _hoisted_3 = { class: "operating-box" }
const _hoisted_4 = { class: "check-box" }
const _hoisted_5 = { class: "menu-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_icon = _resolveComponent("elem-icon")!
  const _component_comp_menu = _resolveComponent("comp-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      maxlength: _ctx.max,
      type: _ctx.hidden ? 'password' : 'text',
      class: "input",
      title: _ctx.title,
      required: _ctx.required,
      name: _ctx.name,
      placeholder: _ctx.place_holder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelDynamic, _ctx.val]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createVNode(_component_elem_icon, {
          class: "icon",
          src: "static/icon/components/elem-input/",
          name: "correct"
        }, null, 512), [
          [_vShow, _ctx.check === true]
        ]),
        _withDirectives(_createVNode(_component_elem_icon, {
          class: "icon",
          src: "static/icon/components/elem-input/",
          name: "error"
        }, null, 512), [
          [_vShow, _ctx.check === false]
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_elem_icon, {
          class: "icon",
          name: "operating"
        }),
        _createVNode(_component_comp_menu, {
          ref: "operatings_menu",
          value: _ctx.operatings,
          position: "follow",
          onSelect: _ctx.onSelectOperating
        }, null, 8, ["value", "onSelect"])
      ])
    ])
  ]))
}