import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "elem-editor-box",
  "dark-class": "elem-editor-box-dark"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_input_object = _resolveComponent("elem-input-object")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_elem_input_object, {
      name: _ctx.name,
      title: _ctx.title,
      required: _ctx.required,
      getValue: _ctx.getTextData
    }, null, 8, ["name", "title", "required", "getValue"]),
    _createElementVNode("div", {
      class: "toolbar-container",
      id: 'toolbar-container-' + _ctx.id
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      class: "editor",
      id: 'editor-' + _ctx.id
    }, null, 8, _hoisted_3)
  ]))
}