import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
        meta: {
            needLogin: true, //需要加校检判断的路由
        },
    },
    {
        path: "/table",
        name: "Table",
        component: () => import("../views/Table.vue"),
    },
    {
        path: "/form",
        name: "Form",
        component: () => import("../views/Form.vue"),
    },
    {
        path: "/config",
        name: "Config",
        component: () => import("../views/Form.vue"),
    },
    {
        path: "/tool",
        name: "Tool",
        component: () => import("../views/Tool.vue"),
    },
    {
        path: "/message",
        name: "Message",
        component: () => import("../views/message/table.vue"),
    },
    {
        path: "/user",
        name: "User",
        component: () => import("../views/User.vue"),
    },
    {
        path: "/link",
        name: "Link",
        component: () => import("../views/Link.vue"),
    },
    {
        path: "/error",
        name: "Error",
        component: () => import("../views/Error.vue"),
    },
    // 员工管理
    {
        path: "/employee",
        name: "Employee",
        component: () => import("../views/pages/employee/table.vue"),
    },
    // 员工管理 - 表单
    {
        path: "/employee/form",
        name: "EmployeeForm",
        component: () => import("../views/pages/employee/form.vue"),
    },
    // 司机管理
    {
        path: "/driver",
        name: "Driver",
        component: () => import("../views/pages/driver/table.vue"),
    },
    // 司机管理 - 表单
    {
        path: "/driver/form",
        name: "DriverForm",
        component: () => import("../views/pages/driver/form.vue"),
    },
    // 车辆管理
    {
        path: "/vehicle",
        name: "Vehicle",
        component: () => import("../views/pages/vehicle/table.vue"),
    },
    // 车辆管理 - 表单
    {
        path: "/vehicle/form",
        name: "VehicleForm",
        component: () => import("../views/pages/vehicle/form.vue"),
    },
    // 维护保养
    {
        path: "/maintaining",
        name: "Maintaining",
        component: () => import("../views/pages/maintaining/table.vue"),
    },
    // 维护保养 - 表单
    {
        path: "/maintaining/form",
        name: "MaintainingForm",
        component: () => import("../views/pages/maintaining/form.vue"),
    },
    // 商品管理
    {
        path: "/goods",
        name: "Goods",
        component: () => import("../views/pages/goods/table.vue"),
    },
    // 商品管理 - 表单
    {
        path: "/goods/form",
        name: "GoodsForm",
        component: () => import("../views/pages/goods/form.vue"),
    },
    // 套餐管理
    {
        path: "/meal",
        name: "Meal",
        component: () => import("../views/pages/meal/table.vue"),
    },
    // 套餐管理 - 表单
    {
        path: "/meal/form",
        name: "MealForm",
        component: () => import("../views/pages/meal/form.vue"),
    },
    // 订单管理
    {
        path: "/order",
        name: "Order",
        component: () => import("../views/pages/order/table.vue"),
    },
    // 收入明细
    {
        path: "/income",
        name: "Income",
        component: () => import("../views/pages/income/table.vue"),
    },
    // 借款登记
    {
        path: "/loans",
        name: "Loans",
        component: () => import("../views/pages/loans/table.vue"),
    },
    // 借款登记 - 表单
    {
        path: "/loans/form",
        name: "LoansForm",
        component: () => import("../views/pages/loans/form.vue"),
    },
    // 支出管理
    {
        path: "/expenses",
        name: "Expenses",
        component: () => import("../views/pages/expenses/table.vue"),
    },
    // 支出管理 - 表单
    {
        path: "/expenses/form",
        name: "ExpensesForm",
        component: () => import("../views/pages/expenses/form.vue"),
    },
    // 发票申请
    {
        path: "/invoice",
        name: "Invoice",
        component: () => import("../views/pages/invoice/table.vue"),
    },
    // 优惠申请审批
    {
        path: "/approval/discount",
        name: "ApprovalDiscount",
        component: () => import("../views/approval/discount/table.vue"),
    },
    // 车辆变更审批
    {
        path: "/approval/vehicle_change",
        name: "ApprovalVehicleChange",
        component: () => import("../views/approval/vehicle_change/table.vue"),
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
})

export default router
