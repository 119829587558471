import { vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "elem-input-box",
  "dark-class": "elem-input-box-dark"
}
const _hoisted_2 = ["maxlength", "type", "verify", "title", "required", "name", "placeholder", "original"]
const _hoisted_3 = { class: "icon-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_icon = _resolveComponent("elem-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      autocomplete: "new-password",
      maxlength: $props.max,
      type: $props.type,
      class: "input",
      verify: $props.verify,
      title: $props.title,
      required: $props.required,
      name: $props.name,
      placeholder: $data.place_holder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.val) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onChange && $options.onChange(...args))),
      original: $data.original,
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onInput && $options.onInput(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelDynamic, $data.val]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createVNode(_component_elem_icon, {
        class: "icon",
        src: "static/icon/components/elem-input/",
        name: "correct"
      }, null, 512), [
        [_vShow, $data.check === true]
      ]),
      _withDirectives(_createVNode(_component_elem_icon, {
        class: "icon",
        src: "static/icon/components/elem-input/",
        name: "error"
      }, null, 512), [
        [_vShow, $data.check === false]
      ])
    ])
  ]))
}