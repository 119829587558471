
import Utils from "@/module/utils/utils"
import Component, { ComponentMethods } from "@/module/component/component"
import Cache from "@/module/cache/cache"
// import Href from "@/module/config/href"
// import Theme from "@/module/theme/theme"
import Message from "@/module/interactive/message"

import elemIcon from "@/components/elem-icon.vue"
import elemOptions from "@/components/elem-options.vue"
import compMenu from "@/components/comp-menu.vue"
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"

import Menu from "@/config/menu"
import CompCollect from "./pages/components/CompCollect.vue"
import CompRobot from "./pages/components/CompRobot.vue"
import ElemService from "./components/ElemService.vue"
import Request from "@/module/request/request"
import Employee from "@/entity/employee"

class Framework {
    private static index: IndexView

    public static setTitle(title: string, icon?: string): void {
        this.index.page_title = title
        this.index.page_icon = icon || "data"
    }

    public static setIndex(index: IndexView) {
        this.index = index
    }
}

class IndexView extends ComponentMethods implements ComponentEntity {
    public page_title: string = "首页"

    public page_icon: string = "data"

    public d_menu_root: string = null

    public submenus = null

    public userMenu = [
        {
            title: "账号管理",
            sub: [
                {
                    id: "edit_password",
                    icon: "password",
                    name: "修改密码",
                },
                {
                    id: "sign_out",
                    icon: "sign_out",
                    name: "退出登录",
                },
            ],
        },
    ]

    public settingMenu = [
        // {
        //     title: "主题",
        //     prompt: "自动模式下，将根据系统主题自动切换浅色模式或深色模式。",
        //     sub: [
        //         {
        //             id: "auto_theme",
        //             icon: "auto",
        //             name: "自动模式",
        //         },
        //         {
        //             id: "light_theme",
        //             icon: "light",
        //             name: "浅色模式",
        //         },
        //         {
        //             id: "dark_theme",
        //             icon: "dark",
        //             name: "深色模式",
        //         },
        //     ],
        // },
        {
            title: "系统设置",
            sub: [
                {
                    id: "robot_config",
                    icon: "read",
                    name: "企业微信机器人配置",
                },
                {
                    id: "pay_config",
                    icon: "read",
                    name: "收钱吧配置",
                },
            ],
        },
    ]

    public edit_password_structure = [
        {
            field: "old_password",
            name: "old_password",
            title: "原密码 (未设置可为空)",
            type: "Password",
            required: false,
            passwordConfig: {
                verify: false,
            },
        },
        {
            field: "new_password",
            name: "new_password",
            title: "新密码 (需包含字母和数字且字数为8-15字)",
            type: "Password",
            required: true,
        },
        {
            field: "confirm_password",
            name: "confirm_password",
            title: "确认密码",
            type: "Password",
            required: true,
        },
    ]

    data() {
        return {
            search_result: null,

            display_search: false,

            setting_info: Cache.get("setting_info"),

            userinfo: Cache.get("userinfo"),

            menus: Menu.get(),
        }
    }

    components = {
        elemIcon,
        compMenu,
        elemOptions,
        CompCollect,
        CompRobot,
        ElemService,
        CompModal,
        ElemForm,
    }

    watch = {
        $route: async function (to) {
            this.processRoute(to)
        },
    }

    created() {
        Framework.setIndex(this)

        // Href.get().then(res => {
        //     this.menus = Utils.copy(res.menus)
        //     this.submenus = Utils.copy(res.submenus)

        //     setTimeout(() => {
        //         this._setMenuChildBoxStyle()
        //     })
        // })

        Cache.onGet("userinfo", v => {
            this.userinfo = v
        })
    }

    mounted() {
        this.processRoute()
    }

    /**
     * 监听窗口大小变化事件
     */
    onResize() {
        this._setMenuChildBoxStyle()
    }

    /**
     * 监听内容组件滚动事件
     */
    onScroll(evt: obj): void {
        const e: HTMLDivElement = evt.target
        var wScrollY = e.scrollTop // 当前滚动条位置
        var wInnerH = e.clientHeight // 设备窗口的高度（不会变）
        var bScrollH = e.scrollHeight // 滚动条总高度
        if (wScrollY + wInnerH >= bScrollH) {
            const page = this.$route.matched[0].instances.default
            // 触发触底事件
            page.onReachBottom && page.onReachBottom()
        }
    }

    processRoute(route?: obj) {
        // 关闭所有消息框
        Message.closeAll()

        Utils.wait(() => {
            if (typeof route === "undefined") {
                route = this.$route
            }

            if (route.matched.length <= 0) {
                return
            }

            const page = route.matched[0].instances.default

            if (!page) return

            // 设置页面标题
            this.page_title = (typeof page.title === "function" ? page.title() : page.title) || "首页"
            // 设置页面图标
            this.page_icon = page.icon || "data"
            // 写入框架方法
            if (typeof page.framework === "undefined") {
                page.framework = Framework
            }
            // 触发 onLoad 事件
            page.onLoad && page.onLoad(page.getParams())
        })
    }

    onSelectSettingMenu(evt: CompEvent<any>) {
        switch (evt.value) {
            case "robot_config":
                this.$refs.comp_robot.display()
                break
        }
    }

    /** 设置子菜单样式 */
    private _setMenuChildBoxStyle() {
        Utils.getElementAll<HTMLDivElement>(".menu-child-base", e => {
            const p = e.getBoundingClientRect()

            if (p.height + p.y > window.innerHeight) {
                if (!e.classList.contains("menu-child-box-bottom")) {
                    e.classList.add("menu-child-box-bottom")
                }
            } else {
                if (e.classList.contains("menu-child-box-bottom")) {
                    e.classList.remove("menu-child-box-bottom")
                }
            }
        })
    }

    /**
     * 获取子菜单模板
     * @param child 子菜单
     * @param isBase 是否添加 base
     */
    public getMenuChild(child: obj[], isBase?: boolean): string {
        var childs = ""

        Utils.each(child, v => {
            childs += `
                <div class="menu-child-item">
                    <a class="menu-child-item-box" ${v.href ? 'href="' + v.href + '" @click="hideMenuChild()"' : ""}>
                        <img class="menu-child-icon" src="static/icon/menu_icon/${v.icon}" />
                        <p class="menu-child-name">${v.name}</p>
                        ${v.child ? '<img class="arrow-icon" src="static/icon/arrow_right_blue.svg" />' : ""}
                    </a>
                    ${v.child ? this.getMenuChild(v.child, true) : ""}
                </div>
            `
        })

        const box = `<div class="menu-child-box">${childs}</div>`

        return isBase ? `<div class="menu-child-base">${box}</div>` : box
    }

    /**
     * 获取移动端子菜单模板
     * @param child 子菜单
     */
    public getMobileMenuChild(child: obj[], isBase?: boolean): string {
        var childs = ""

        Utils.each(child, v => {
            childs += `
                <li>
                    <a ${v.href ? 'href="' + v.href + '"' : ""}>${v.name}</a>
                    ${v.child ? this.getMobileMenuChild(v.child, true) : ""}
                </li>
            `
        })

        return isBase ? `<ul>${childs}</ul>` : childs
    }

    public hideMenuChild() {
        const dom = document.querySelectorAll<HTMLDivElement>(".menu-child-box")

        if (dom.length > 0) {
            Utils.eachNode(dom, v => {
                v.hidden = true
                // 使用延时将子菜单可显示状态
                setTimeout(() => {
                    v.hidden = false
                }, 100)
            })
        }
    }

    /**
     * 监听用户菜单
     * @param evt 值
     */
    onSelectUserMenu(evt: obj): void {
        const value = evt.value

        switch (value) {
            case "sign_out":
                Message.info("确认退出当前账号？", true)
                    .onConfirm(() => {
                        // 移除所有缓存
                        Cache.clear()
                        // 刷新页面
                        window.location.reload()
                    })
                    .build()
                break
            case "edit_password":
                this.$refs.edit_password_modal.display()
        }
    }

    /**
     * 提交修改密码
     */
    async onEditPassword() {
        const data = await this.$refs.edit_password_form.getFormData()

        if (data.new_password !== data.confirm_password) {
            Message.error("两次密码不一致")
            return
        }

        Request.post("WEB://Employee/UpdatePassword", {
            oldPassword: data.old_password || "",
            newPassword: data.new_password,
        }).then(() => {
            Message.success("修改成功")
            this.$refs.edit_password_modal.close()
        })
    }

    isAdmin() {
        return Employee.isAdmin()
    }
}

export default Component.build(new IndexView())
