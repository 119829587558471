import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "elem-input-box",
  "dark-class": "elem-input-box-dark"
}
const _hoisted_2 = ["title", "required", "name", "placeholder"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "countdown-box"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      autocomplete: "new-password",
      class: "input",
      title: $props.title,
      required: $props.required,
      name: $props.name,
      placeholder: $data.place_holder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.val) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onChange && $options.onChange(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelText, $data.val]
    ]),
    ($props.type === 'code')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "code-box",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.getValidateCode && $options.getValidateCode(...args)))
        }, [
          ($data.codeInfo)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "code",
                src: $data.codeInfo.image,
                width: "70",
                height: "30"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ]))
      : ($props.type === 'phone')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            ($data.countdown > 0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($data.countdown), 1))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "again",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.sendPhoneCode && $options.sendPhoneCode(...args)))
                }, "重发验证码"))
          ]))
        : _createCommentVNode("", true)
  ]))
}