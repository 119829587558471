import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, withKeys as _withKeys, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "elem-label-box" }
const _hoisted_2 = {
  key: 0,
  class: "label-box"
}
const _hoisted_3 = { class: "value" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "input-box"
}
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 2,
  class: "textarea-box"
}
const _hoisted_8 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_input_object = _resolveComponent("elem-input-object")!
  const _component_elem_icon = _resolveComponent("elem-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_elem_input_object, {
      name: $props.name,
      getValue: $options.getValue
    }, null, 8, ["name", "getValue"]),
    _createElementVNode("div", {
      class: _normalizeClass([$props.type === 'input' ? 'input-label-box' : 'textarea-label-box'])
    }, [
      ($data.labels && $data.labels.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.labels, (item, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "label-item-box",
                key: idx
              }, [
                _createElementVNode("p", _hoisted_3, _toDisplayString(item), 1),
                _createElementVNode("div", {
                  class: "delete-btn",
                  onClick: _withModifiers(($event: any) => ($options.onDeleteLabel(idx)), ["stop"])
                }, [
                  _createVNode(_component_elem_icon, { name: "close_white" })
                ], 8, _hoisted_4)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      ($props.type === 'input')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("input", {
              class: "input",
              type: "text",
              onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => ($options.onAppendLabel && $options.onAppendLabel(...args)), ["enter"])),
              placeholder: $data.place_holder,
              onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onBlur && $options.onBlur(...args)))
            }, null, 40, _hoisted_6)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("textarea", {
              class: "textarea",
              onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => ($options.onAppendLabel && $options.onAppendLabel(...args)), ["enter"])),
              placeholder: $data.place_holder,
              onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.onBlur && $options.onBlur(...args)))
            }, null, 40, _hoisted_8)
          ]))
    ], 2)
  ]))
}