export default class Path {
    /** 生产环境根域名 */
    public static PRO_DOMAIN = "https://api.ys.meshel.cn"
    /** 测试环境根域名 */
    public static TEST_DOMAIN = "http://test.shltds.com"
    /** 开发环境根域名 */
    public static DEV_DOMAIN = "http://localhost:8000"

    /** 根域名 */
    public static DOMAIN = process.env.NODE_ENV === "development" ? Path.DEV_DOMAIN : Path.PRO_DOMAIN

    /** Admin API 域名地址 */
    public static ADMIN = Path.DOMAIN + "/AdminAPI/"

    /** Web API 域名地址 */
    public static WEB = Path.DOMAIN + "/WebAPI/"
}
