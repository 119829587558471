
import Utils from "../module/utils/utils"

import elemIcon from "./elem-icon.vue"
import compMenu from "./comp-menu.vue"
import elemPrompt from "./elem-prompt.vue"
import Component, { ComponentMethods } from "@/module/component/component"
import Status from "@/module/status/status"

class ElemPasswordComponent extends ComponentMethods implements ComponentEntity {
    private check: Status<any> = null
    private val: string = null
    private place_holder: string = null
    private hidden: boolean = true
    private change: boolean = false
    private type: "yuan" | "penny" = "yuan"

    public language = {
        enter: "输入",
        optional: "可选",
    }

    private operatings = [
        {
            title: "价格单位",
            prompt: "单位为“分”时不可使用小数点",
            sub: [
                {
                    id: "UnitYuan",
                    icon: "price",
                    name: "元",
                },
                {
                    id: "UnitPenny",
                    icon: "price",
                    name: "分",
                },
            ],
        },
        {
            title: "关于",
            sub: [
                {
                    icon: "version",
                    name: "组件版本",
                    value: "1.0.0",
                },
            ],
        },
    ]

    props = {
        config: {
            type: Object,
            default: {
                encryption: true,
            },
        },
        max: {
            type: Number,
            default: 255,
        },
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        value: null,
    }

    components = {
        elemIcon,
        compMenu,
        elemPrompt,
    }

    watch = {
        val(value: string) {
            this.onCheck(value)
        },
        type() {
            this.onCheck(this.val)
        },
        value: {
            handler(value: number) {
                this.val = Utils.isExist(value) ? String(value / 100) : ""
            },
            immediate: true,
        },
    }

    mounted() {
        this.place_holder = this.placeholder || `${this.language.enter}${this.title || this.name}${this.required ? "" : "(" + this.language.optional + ")"}`

        this.$refs.input.getValue = this.getValue.bind(this)
    }

    onChange() {
        this.$emit("change", {
            value: this.getVal(),
            type: "elem-input",
            name: this.name,
            set: (value: string) => {
                this.val = value
            },
        })
    }

    onInput() {
        this.$emit("input-event", {
            value: this.getVal(),
            type: "elem-input",
            name: this.name,
            set: (value: string) => {
                this.val = value
            },
        })
    }

    getValue(): Status<any> {
        var value = this.val
        var res: number

        let check = this.onCheck(value)

        if (check.isBlank()) {
            return check
        }

        switch (this.type) {
            case "yuan":
                res = Number(value) * 100
                break
            case "penny":
                res = Number(value)
        }

        return new Status(200).setData(res)
    }

    onCheck(value: string): Status<any> {
        var check: Status<any>

        if (typeof value === "number") {
            value = String(value)
        }

        if (Utils.isBlank(value)) {
            check = this.check = null
        } else if (this.type === "penny" && value.indexOf(".") > -1) {
            check = this.check = Status.of(400).setMessage("单位为“分”时不可使用小数点")
        } else if (this.type === "yuan" && value.indexOf(".") > -1 && value.indexOf(".") + 3 < value.length) {
            check = this.check = Status.of(400).setMessage("单位为“元”时小数不能超过2位")
        } else {
            check = this.check = Status.of(200)
        }

        return check === null ? (this.required ? Status.of(400).setMessage(`${this.title} 不能为空`) : Status.of(200)) : check
    }

    onSelectOperating(evt: obj) {
        switch (evt.value) {
            case "UnitYuan":
                this.type = "yuan"
                break
            case "UnitPenny":
                this.type = "penny"
                break
        }
    }

    getVal() {
        var value = this.val
        var res: number

        if (Utils.isBlank(value)) {
            return null
        }

        if (Number(value) === 0) {
            return 0
        }

        switch (this.type) {
            case "yuan":
                res = Number(value) * 100
                break
            case "penny":
                res = Number(value)
        }

        return res
    }
}

export default Component.build(new ElemPasswordComponent())
