
import Component, { ComponentMethods } from "../module/component/component"
import Utils from "../module/utils/utils"

import elemIcon from "./elem-icon.vue"

class CompMenuComponent extends ComponentMethods implements ComponentEntity {
    private parent: HTMLElement

    data() {
        return {
            display: false,
            menus: null,
        }
    }

    props = {
        value: Array,
        cursor: {
            type: String,
            default: "pointer",
        },
        clickType: {
            type: String,
            default: "left",
        },
        position: {
            type: String,
            default: "fixed",
        },
        direction: {
            type: String,
            default: "bottom",
        },
        absolute: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: "auto",
        },
        width: {
            type: String,
            required: false,
        },
    }

    components = {
        elemIcon,
    }

    mounted() {
        const dom: HTMLDivElement = this.$el

        const parent = (this.parent = dom.parentElement)

        if (!this.absolute && this.position !== "follow") {
            parent.style.position = "relative"
        }

        parent.tabIndex = 0

        if (!parent.style.cursor) {
            parent.style.cursor = this.cursor
        }

        if (this.position === "follow") {
            dom.style.position = "fixed"

            // 移动到 body 层级，不受父组件样式影响
            document.body.appendChild(this.$el)
        }

        dom.style.userSelect = "none"

        const display = async (evt: obj) => {
            if (this.position === "follow") {
                evt.preventDefault()
            }

            this.evt = evt
            this.display = false

            const menu: obj[] = this.getMenu(evt)

            if (menu) {
                for (let i = 0; i < menu.length; i++) {
                    let m = menu[i]
                    // 触发创建事件
                    m.onCreate && m.onCreate(evt, m)
                }
                // 拷贝对象
                let menus = Utils.copy(this.value)
                // 添加组件菜单
                menus.push({
                    title: "组件菜单",
                    sub: menu,
                })
                this.menus = menus
            } else {
                this.menus = this.value
            }

            Utils.wait(() => {
                const p = dom.getBoundingClientRect()

                if (this.position === "fixed") {
                    let pr = parent.getBoundingClientRect()

                    if (p.width + pr.x + 100 > window.innerWidth) {
                        dom.style.left = -p.width + pr.width + "px"
                    } else {
                        dom.style.left = "-10px"
                    }

                    if (p.height + pr.y + 100 > window.innerHeight) {
                        dom.style.top = -p.height + "px"
                    } else {
                        dom.style.top = "100%"
                    }
                } else {
                    var x, y

                    if (evt.type.indexOf("touch") > -1) {
                        x = evt.changedTouches[0].pageX
                        y = evt.changedTouches[0].pageY
                    } else {
                        x = evt.x
                        y = evt.y
                    }

                    if (p.width + x + 100 > window.innerWidth) {
                        dom.style.left = x - p.width + "px"
                    } else {
                        dom.style.left = x + "px"
                    }

                    if (p.height + y + 100 > window.innerHeight) {
                        dom.style.top = y - p.height + "px"
                    } else {
                        dom.style.top = y + "px"
                    }
                }

                this.display = true
            })
        }

        const hide = () => {
            setTimeout(
                () => {
                    this.display = false
                },
                this.position === "follow" ? 200 : 0
            )
        }

        if (this.clickType === "left") {
            parent.onclick = display
            parent.onblur = hide
        } else {
            parent.oncontextmenu = display
            parent.onclick = parent.onblur = hide

            var duration: Date

            parent.addEventListener("touchstart", () => {
                duration = new Date()
                return false
            })

            parent.addEventListener("touchend", evt => {
                if (new Date().getTime() - duration.getTime() > 300) {
                    evt.preventDefault()
                    display(evt)
                } else {
                    hide()
                }
                return false
            })
        }

        window.addEventListener(
            "mousewheel",
            evt => {
                if (this.display) {
                    evt.preventDefault()
                }
            },
            { passive: false }
        )
    }

    getMenu(evt: obj) {
        const path: obj[] = evt.path || (evt.composedPath && evt.composedPath())

        for (let i = 0; i < path.length; i++) {
            let e = path[i]

            if (e.menu) {
                return e.menu
            }
        }

        return null
    }

    onSelectMenu(e: obj) {
        e.onClick && e.onClick(this.evt, e.id)

        !e.display &&
            this.$emit("select", {
                value: e.id,
                data: e,
                type: "comp-menu",
            })

        // 触发失焦事件
        this.parent.blur()
    }

    /**
     * 监听禁用状态变化
     */
    onChangeDisable(id: string, disable: boolean) {
        Utils.each<obj>(this.value, v => {
            Utils.each<obj>(
                v.sub,
                s => {
                    s.disable = disable
                },
                c => c.id === id
            )
        })
    }
}

export default Component.build(new CompMenuComponent())
