
import Utils from "@/module/utils/utils"
import elemFormItem from "./elem-form-item.vue"

import Message from "@/module/interactive/message"

export default {
    components: {
        elemFormItem,
    },

    data() {
        return {
            id: Utils.getUuid(),
            formdata: {},
        }
    },

    props: {
        structure: [Array, Object],

        value: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    watch: {
        value: {
            handler(val) {
                this.formdata = val || {}
            },
            immediate: true,
        },
    },

    methods: {
        /**
         * 清空表单数据
         */
        clear() {
            this.formdata = {}
        },

        onChangeValue(evt) {
            this.formdata[evt.name] = evt.value
        },

        async getFormData() {
            return new Promise(resolve => {
                Utils.getFormData("#" + this.id).then(res => {
                    if (res.isBlank()) {
                        return Message.error(res.getMessage())
                    }

                    resolve(res.getData())
                })
            })
        },
    },
}
