
import elemInputObject from "./elem-input-object.vue"
import Theme from "../module/theme/theme"

export default {
    data() {
        return {
            radius: null,
            val: null,
            theme: Theme.getTheme(),
        }
    },

    props: {
        on: {
            type: String,
            default: "ON",
        },
        required: {
            type: Boolean,
            default: false,
        },
        off: {
            type: String,
            default: "OFF",
        },
        name: {
            type: String,
            default: "switch",
        },
        value: {
            type: Boolean,
            default: false,
        },

        // 大小，小：small 中：medium
        size: {
            type: String,
            default: "medium",
        },
        // 形状，圆：circle 方：square
        shape: {
            type: String,
            default: "circle",
        },
    },

    components: {
        elemInputObject,
    },

    watch: {
        value: {
            handler(val: boolean) {
                this.val = val
            },
            immediate: true,
        },
    },

    mounted() {
        Theme.processPage(this.$el)
    },

    created() {
        if (this.shape === "circle") {
            this.radius = this.size === "medium" ? 20 : 15
        } else {
            this.radius = 6
        }

        Theme.onChange(theme => {
            this.theme = theme
        })
    },

    methods: {
        onChangeSwitch(val: boolean) {
            if (val != this.val) {
                this.val = val

                this.onChange(val)
            }
        },

        onChange(val: boolean) {
            this.$emit("change", {
                value: val,
                type: "elem-switch",
                name: this.name,
                restore: () => {
                    this.val = !val
                },
            })
        },
    },
}
