import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "elem-textarea-box",
  "dark-class": "elem-textarea-box-dark"
}
const _hoisted_2 = ["name", "title", "placeholder", "required", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("textarea", {
      class: "textarea",
      name: _ctx.name,
      title: _ctx.title,
      placeholder: _ctx.place_holder,
      required: _ctx.required,
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      innerHTML: _ctx.val
    }, null, 40, _hoisted_2)
  ]))
}