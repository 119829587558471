import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "elem-icon-page",
    onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onEnter && _ctx.onEnter(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLeave && _ctx.onLeave(...args)))
  }, [
    _createElementVNode("div", {
      class: "elem-icon",
      style: _normalizeStyle({ width: _ctx.width, height: _ctx.height }),
      innerHTML: _ctx.html
    }, null, 12, _hoisted_1)
  ], 32))
}