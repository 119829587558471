import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "elem-prompt-page",
  "dark-class": "elem-prompt-page-dark"
}
const _hoisted_2 = { class: "prompt-box" }
const _hoisted_3 = { class: "prompt-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1)
    ])
  ], 512)), [
    [_vShow, _ctx.display]
  ])
}