<template>
    <div class="elem-menu-bar-box" dark-class="elem-menu-bar-box-dark" :class="design">
        <div class="menu-box">
            <div class="slider" :style="{ width: 100 / menu.length + '%', left: activity * (100 / menu.length) + '%' }"></div>
            <div class="item-box" :style="{ width: 100 / menu.length + '%' }" v-for="(item, idx) in menu" :key="idx" :class="{ activity: idx === activity }" @click="onChangeItem(item, idx)">
                {{ item.title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activity: 0,
        }
    },

    model: {
        prop: "value",
        event: "change",
    },

    props: {
        menu: {
            type: Array,
            default: new Array(),
        },
        value: {
            type: String,
            required: false,
        },

        design: {
            type: String,
            default: "default",
        },
    },

    watch: {
        value(v) {
            this.setValue(v)
        },
    },

    created() {
        if (this.value) {
            this.setValue(this.value)
        }
    },

    methods: {
        setValue(v) {
            for (let i = 0, m = this.menu; i < m.length; i++) {
                if (m[i].id === v) {
                    this.activity = i
                    break
                }
            }
        },

        onChangeItem(item, idx) {
            this.activity = idx
            this.$emit("change", item.id)
        },
    },
}
</script>

<style lang="less">
@import (reference) "/src/style/utils.less";
@import (reference) "/src/style/color.less";

.elem-menu-bar-box {
    height: 45px;

    .menu-box {
        position: relative;
        width: 100%;
        height: 45px;
        border: 1px solid #e3e3e3;
        background: #fff;
        border-radius: 5px;
        display: flex;
        box-sizing: border-box;
        transition: all 0.2s;

        &:hover {
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        }

        .slider {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: 50%;
            transition: 0.3s all ease;

            &::after {
                content: "";
                background: #2faaf7;
                position: absolute;
                top: 5px;
                left: 5px;
                bottom: 5px;
                right: 5px;
                border-radius: 3px;
            }
        }

        > .item-box {
            cursor: pointer;
            position: relative;
            height: 100%;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.3s all ease;
            color: #888;
            font-weight: bold;
        }

        > .activity {
            color: #fff;
        }
    }

    &.hollow {
        .menu-box {
            border-color: rgba(255, 255, 255, 0.2);
            background: transparent !important;
        }
    }
}

.elem-menu-bar-box-dark {
    .menu-box {
        background: #252a31;
        border-color: @dark_border;
    }
}
</style>
