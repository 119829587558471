import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "elem-select-box" }
const _hoisted_2 = {
  class: "select-base",
  "dark-class": "select-base-dark"
}
const _hoisted_3 = { class: "first-box" }
const _hoisted_4 = {
  key: 0,
  class: "label-box"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["placeholder", "disabled"]
const _hoisted_7 = { class: "drop-icon" }
const _hoisted_8 = ["value", "selected", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_input_object = _resolveComponent("elem-input-object")!
  const _component_elem_icon = _resolveComponent("elem-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_elem_input_object, {
      name: $props.name,
      value: $data.val,
      title: $props.title,
      required: $props.required
    }, null, 8, ["name", "value", "title", "required"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["select-box", { unfold: $data.unfold }])
      }, [
        _createElementVNode("div", _hoisted_3, [
          ($props.multiple)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.label, (item, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "label-item-box",
                    key: idx
                  }, [
                    _createElementVNode("p", null, _toDisplayString(item), 1),
                    _createElementVNode("div", {
                      class: "delete-btn",
                      onClick: _withModifiers(($event: any) => ($options.onDeleteLabel(idx)), ["stop"])
                    }, [
                      _createVNode(_component_elem_icon, {
                        src: "static/icon/components/elem-select/",
                        name: "close"
                      })
                    ], 8, _hoisted_5)
                  ]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.val_text) = $event)),
            class: "input",
            type: "text",
            placeholder: $data.ph,
            disabled: $props.disabled,
            onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.onInputFocus && $options.onInputFocus(...args))),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.onInputBlur && $options.onInputBlur(...args))),
            onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.onSearchSelect && $options.onSearchSelect(...args)))
          }, null, 40, _hoisted_6), [
            [_vModelText, $data.val_text]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _withDirectives(_createVNode(_component_elem_icon, {
            src: "static/icon/components/elem-select/",
            name: "arrow_bottom",
            width: "50%",
            height: "50%"
          }, null, 512), [
            [_vShow, $data.val == null && !$data.val_text]
          ]),
          _withDirectives(_createVNode(_component_elem_icon, {
            src: "static/icon/components/elem-select/",
            name: "correct",
            width: "60%",
            height: "60%"
          }, null, 512), [
            [_vShow, $data.val != null]
          ]),
          _withDirectives(_createVNode(_component_elem_icon, {
            src: "static/icon/components/elem-select/",
            name: "error",
            width: "60%",
            height: "60%"
          }, null, 512), [
            [_vShow, $data.val == null && $data.val_text]
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["item-base", { unfold: $data.unfold }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.data_list, (item, idx) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "item-box",
              key: idx,
              value: item.id,
              selected: item.id == $props.value,
              onClick: ($event: any) => ($options.onSelect(item.id, item.value))
            }, _toDisplayString(item.value), 9, _hoisted_8)), [
              [_vShow, !$data.search || item.value.indexOf($data.search) > -1]
            ])
          }), 128))
        ], 2)
      ], 2)
    ])
  ]))
}