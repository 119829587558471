
import Utils from "@/module/utils/utils"

const COMP_MODAL_WINDOWS = (window["COMP_MODAL_WINDOWS"] = [])

export default {
    name: "CompModal",

    data() {
        return {
            id: Utils.getUuid(),
            status: false,
            // 加载池
            loadPool: 0,
        }
    },

    props: {
        title: String,

        width: {
            type: String,
            default: null,
        },

        height: {
            type: String,
            default: "auto",
        },

        maxWidth: {
            type: String,
            default: "1920px",
        },
        // 持续显示，加载即显示
        continued: {
            type: Boolean,
            default: false,
        },
        // 全屏显示
        isFull: {
            type: Boolean,
            default: false,
        },
        // 最高层级
        topLevel: {
            type: Boolean,
            default: false,
        },
        // 监听关闭
        onClose: {
            type: Function,
            required: false,
        },
        // 监听提交按钮
        onSuccess: {
            type: Function,
            required: false,
        },
        // 可否滚动
        isScroll: {
            type: Boolean,
            default: true,
        },
    },

    created() {
        this.winow_config = {
            v: this,
            id: this.id,
            status: false,
        }

        Object.defineProperty(this.winow_config, "status", {
            set: v => {
                // let page = document.body.querySelector(".page")
                let e = document.getElementById(this.id)

                if (v) {
                    e.classList.remove("hide")
                    e.classList.add("show")
                    // 设置高斯模糊
                    // page.style.filter = "blur(3px)"
                } else {
                    e.classList.remove("show")
                    e.classList.add("hide")
                    // 恢复高斯模糊
                    // page.style.filter = "initial"
                }

                this.status = v
            },
            get: () => {
                return this.status
            },
        })

        COMP_MODAL_WINDOWS.push(this.winow_config)
    },

    mounted() {
        // 移动到 body 层级，不受父组件样式影响
        document.body.appendChild(this.$el)
        // 观察组件
        this.observer = window.MutationObserver
            ? new window.MutationObserver(mutationsList => {
                  for (let mutation of mutationsList) {
                      if (mutation.type === "childList" && this.isDisplay() && this.loadPool <= 0) {
                          this.checkLoad(mutation.target)
                      }
                  }
              })
            : null
        // 开始观察
        this.observer?.observe(this.$refs.content, {
            childList: true,
        })
        // 持续显示，加载完毕即显示
        this.continued && this.onDisplay()
    },

    methods: {
        /**
         * 判断当前窗口是否显示中
         */
        isDisplay() {
            return this.winow_config.status
        },

        /** 显示 */
        display() {
            for (let i = 0, ws = COMP_MODAL_WINDOWS; i < ws.length; i++) {
                let w = ws[i]

                if (w.status) {
                    w.status = false
                    // 记录将它关闭的窗口ID
                    w.close_id = this.winow_config.id
                }
            }
            this.winow_config.status = true
            this.$nextTick(() => {
                // 检验加载
                this.checkLoad(this.$refs.content)
            })
        },

        /** 关闭 */
        close() {
            // 关闭当前页面
            this.winow_config.status = false

            for (let i = 0, ws = COMP_MODAL_WINDOWS; i < ws.length; i++) {
                let w = ws[i]
                // 判断当前是否为将它关闭的ID，重新打开
                if (!w.status && w.close_id && w.close_id === this.winow_config.id) {
                    w.status = true
                    delete w.close_id
                }
            }

            // 触发关闭事件
            setTimeout(() => {
                this.$emit("on-close", {
                    tag: "Compmodal",
                })
            }, 300)
        },

        /** 关闭全部 */
        closeAll() {
            for (let i = 0, ws = COMP_MODAL_WINDOWS; i < ws.length; i++) {
                let w = ws[i]
                // 开启的窗口都关闭
                if (w.status) {
                    w.status = false
                    // 触发关闭事件
                    setTimeout(() => {
                        w.v.$emit("on-close", {
                            tag: "Compmodal",
                        })
                    }, 300)
                }
            }
        },

        onScroll(evt) {
            const t = evt.target
            if (t.scrollHeight - 10 <= t.scrollTop + t.clientHeight) {
                if (!this.bottoming) {
                    this.$emit("on-scroll-bottom")
                }
                this.bottoming = true
            } else {
                this.bottoming = false
            }
        },

        checkLoad(elem) {
            let l = elem?.getElementsByTagName?.("img")
            // 置空
            this.loadPool = 0

            if (l?.length > 0) {
                for (let i = 0; i < l.length; i++) {
                    var item = l[i]
                    // 已加载完毕或已监听加载
                    if (item.complete || item.onload) {
                        continue
                    }
                    // 添加到池
                    this.loadPool++
                    item.onload = () => {
                        // 清除
                        this.loadPool--
                        this.checkLoaded()
                    }
                    item.onerror = () => {
                        this.$Message.error("图片加载失败")
                    }
                }
            }

            this.checkLoaded()
        },

        /**
         * 检查是否加载完毕
         */
        checkLoaded() {
            clearTimeout(this.checkLoadedTimeout)

            if (this.loadPool <= 0) {
                return setTimeout(() => {
                    // 触发加载完成事件
                    this.$emit("on-load", {
                        tag: "CompModal",
                    })
                }, 100)
            }

            this.checkLoadedTimeout = setTimeout(() => {
                this.checkLoaded()
            }, 500)
        },
    },
}
