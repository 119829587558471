
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"
import Message from "@/module/interactive/message"
import Request from "@/module/request/request"

export default {
    components: {
        CompModal,
        ElemForm,
    },

    data() {
        return {
            robot_structure: [
                {
                    field: "botName",
                    name: "botName",
                    title: "机器人名称",
                    type: "Input",
                    required: true,
                },
                {
                    field: "botUrl",
                    name: "botUrl",
                    title: "机器人链接",
                    type: "Textarea",
                    required: true,
                },
            ],

            robot_value: null,
        }
    },

    methods: {
        display() {
            // 获取机器人信息
            this.getRobotInfo()
            // 显示弹窗
            this.$refs.robot_modal.display()
        },

        /**
         * 获取机器人信息
         */
        getRobotInfo() {
            Request.get("WEB://wechat/bot/FindByCompany").then(res => {
                this.robot_value = res
            })
        },

        /**
         * 提交机器人信息
         */
        async onSubmitRobot() {
            const data = await this.$refs.robot_form.getFormData()

            Request.post("WEB://wechat/bot/Save", data).then(() => {
                Message.success("保存成功")
            })
        },

        /**
         * 关闭弹窗
         */
        onCloseRobotModal() {
            this.$refs.robot_form.clear()
        },
    },
}
