
import Component, { ComponentMethods } from "@/module/component/component"
import Utils from "@/module/utils/utils"
import FileUtil from '@/module/file/file_util'

class ElemEditorComponent extends ComponentMethods implements ComponentEntity {
    data() {
        return {
            id: Utils.getUuid(),
            place_holder: "",
            val: ""
        }
    }
    
	props = {
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        value: {
            type: String,
            default: ""
        }
    }
    
    async mounted() {
        console.log(this.name)

        this.place_holder = this.placeholder || `输入${this.title || this.name}${this.required ? '' : '（可选）'}`

        this.val = this.value

        await FileUtil.script("static/codemirror/codemirror.js")
        await FileUtil.script("static/codemirror/javascript.js")
        await FileUtil.script("static/codemirror/format.js")

		// 初始化代码编辑器
        var editor = window['CodeMirror'].fromTextArea(document.getElementById(this.id), {
            lineNumbers: true,
            mode: 'application/json',
            theme:"idea",
            lint: true
        })

        // 设置尺寸
        editor.setSize("100%", 500)

        // 格式化代码
        editor.autoFormatRange({ line: 0, ch: 0 }, { line: editor.lineCount() })
        
        // 取消选择
        editor.setSelection({ line: 0, ch: 0 })

        editor.on("blur", () => {
            this.val = editor.getValue()
        })
    }

	methods = {
		onSubmit() {
            console.log("onSubmit")
        }
	}
}

export default Component.build(new ElemEditorComponent)
