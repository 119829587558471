
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"
import ElemIcon from "@/components/elem-icon.vue"
import Message from "@/module/interactive/message"
import Request from "@/module/request/request"

export default {
    components: {
        CompModal,
        ElemForm,
        ElemIcon,
    },

    data() {
        return {
            collect_structure: [
                {
                    field: "price",
                    name: "price",
                    title: "收款金额",
                    type: "Price",
                    required: true,
                },
            ],

            isListening: false,

            code: "",
        }
    },

    methods: {
        display() {
            this.$refs.collect_modal.display()

            Request.post("WEB://BusinessOrder/CreateSQBPaymentCodeOrder", {
                code: "",
                price: 0,
                title: "",
            }).then(res => {
                console.log(res)
            })
        },

        async onCodeScanner() {
            const data = await this.$refs.collect_form.getFormData()

            if (!data.price) {
                return Message.error("请输入收款金额")
            }

            this.$refs.code_scanner_input.focus()

            Message.info("请使用扫描器扫描付款二维码")
        },

        async onChangeCodeScanner(evt) {
            const value = evt.target.value

            if (!value) {
                return
            }

            const data = await this.$refs.collect_form.getFormData()

            if (!data.price) {
                return Message.error("请输入收款金额")
            }

            Request.post("WEB://BusinessOrder/CreateSQBPaymentCodeOrder", {
                code: value,
                price: data.price,
                title: "付款码付款"
            }).then(() => {
                // 关闭弹窗
                this.$refs.collect_modal.close()
                // 清空表单
                this.$refs.collect_form.clear()

                Message.success("收款成功")
            })
        },
    },
}
