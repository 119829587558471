import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "name", "title", "value", "required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    ref: "input",
    id: $props.id,
    name: $props.name,
    title: $props.title,
    value: JSON.stringify($props.value),
    required: $props.required,
    hidden: ""
  }, null, 8, _hoisted_1))
}