
import RequestPage from "@/module/request/page"
import Utils from "@/module/utils/utils"
import ElemRender from "@/module/entity/header"
import CompEntity from "./comp-entity.vue"

import { h } from "vue"

export default {
    components: {
        ElemRender,
        CompEntity,
    },

    data() {
        return {
            // 表格数据
            tableData: null,
            // 当前页码
            currentPage: 1,
            // 页面大小
            pageSize: 20,
            // 总数
            total: 0,
            // 统计
            sums: {},
            // 选择器
            selecteds: [],
            // 是否加载中
            isLoading: false,
            // 隐藏 Page 控制器
            hidePage: false,
        }
    },

    props: {
        // 实体名称
        entity: {
            type: String,
            required: false,
        },
        // 大标题
        title: {
            type: String,
            required: false,
        },
        // 是否显示表头
        head: {
            type: Boolean,
            default: true,
        },
        // 列配置
        columns: Object,
        // 接口
        tableApi: {
            type: String,
            required: false,
        },
        // 高度
        height: {
            type: String,
            default: "auto",
        },
        // 数据列表
        dataList: {
            type: Array,
            required: false,
        },
        // 显示选择器
        isSelector: {
            type: Boolean,
            default: false,
        },
        // 显示序号
        isIndex: {
            type: Boolean,
            default: false,
        },
        // 主题色
        color: {
            type: String,
            default: "#2faaf7",
        },
        // 展开（无最大高度限制）
        expand: {
            type: Boolean,
            default: false,
        },
        // 请求方法
        method: {
            type: String,
            default: "get",
        },
        // 处理分页配置
        processPageConfig: {
            type: Function,
            required: false,
        },
        // 分页参数 key 值名称
        keys: {
            type: Object,
            default: new Object(null),
        },
        // 接口数据
        data: null,
        // 数据处理
        processData: {
            type: Function,
            required: false,
        },
    },

    watch: {
        tableApi(v) {
            if (!v) return
            // 写入 url
            this.req.setUrl(v)
        },

        dataList: {
            handler(v) {
                if (!v) return
                this.tableData = JSON.parse(JSON.stringify(v))
                // 处理数据
                this.onProcessData()
            },
            immediate: true,
        },

        columns: {
            handler(v) {
                if (!v) return
                // 处理数据
                this.onProcessData()
            },
            immediate: true,
        },

        selecteds(v) {
            this.$emit("on-selector", {
                tag: "ElemTable",
                value: v,
            })
        },

        tableData(v) {
            this.$emit("on-load", {
                tag: "ElemTable",
                value: v,
            })
        },
    },

    mounted() {
        const url = this.tableApi || (this.entity ? `WEB://${this.entity}/FindAllToPage` : null)
        this.req = new RequestPage(url, {
            method: this.method.toUpperCase(),
            json: this.method.toUpperCase() === "POST", // post 请求时内容为 json 类型
            type: "block", // 块状，不合并
            data: this.data,
            keys: this.keys,
            load: !!url,
            processPageConfig: this.processPageConfig,
            processData: res => {
                this.hidePage = !res.number && !res.totalPages && !res.pageable

                if (this.processData) {
                    return this.processData(res)
                }
            },
            onChange: data => {
                this.selecteds = []
                this.tableData = data
                this.currentPage = this.req.getPage()
                this.total = this.req.getTotal()
                // 处理数据
                this.onProcessData()
            },
            onLoadBefore: () => {
                this.isLoading = true
            },
            onLoadAfter: () => {
                // 滚动到顶部 & 左侧
                this.$refs.table_box.scrollTo(0, 0)
                // 延迟 300ms 关闭
                setTimeout(() => {
                    this.isLoading = false
                }, 300)
            },
            onFail: err => {
                console.log(err)
            },
        })
    },

    methods: {
        /**
         * 显示详情
         * @param uuid 记录 UUID
         */
        detail(uuid: string) {
            this.$refs.comp_entity.display(uuid)
        },

        /**
         * 处理单元格数据
         */
        onProcessData() {
            for (let i = 0, cs = this.columns; i < cs.length; i++) {
                const c = cs[i]

                Utils.each<obj>(this.tableData, v => {
                    if (!v._id) {
                        v._id = Utils.getUuid()
                    }
                })

                // 排序
                if (c.sort && this.tableData?.length > 0) {
                    if (typeof c.sort === "function") {
                        this.tableData.sort((a, b) => c.sort(this.getValue(c, a), this.getValue(c, b)))
                    } else
                        c.sort === "desc"
                            ? this.tableData.sort((a, b) => {
                                  a = this.getValue(c, a)
                                  b = this.getValue(c, b)

                                  if (typeof b !== "number") return -1
                                  return b - a
                              })
                            : this.tableData.sort((a, b) => {
                                  a = this.getValue(c, a)
                                  b = this.getValue(c, b)

                                  if (typeof a !== "number") return -1
                                  return a - b
                              })
                }

                if (c.sum) {
                    var sum = 0

                    if (this.tableData?.length > 0) {
                        for (let di = 0, ds = this.tableData; di < ds.length; di++) {
                            let v = ds[di]
                            var value = this.getValue(c, v)

                            if (typeof value === "number") {
                                sum += value
                            }
                        }
                    }

                    this.sums[c.key] = sum
                }

                if (c.width) {
                    c._w = typeof c.width === "number" ? c.width + "px" : c.width
                    continue
                } else if (c.minWidth) {
                    c._w = typeof c.minWidth === "number" ? c.minWidth + "px" : c.minWidth
                    continue
                } else c._w = null

                c._w = this.getMinWidth(c.title)

                if (this.tableData?.length > 0) {
                    for (let di = 0, ds = this.tableData; di < ds.length; di++) {
                        let v = ds[di]
                        var val = null

                        if (c.key && v[c.key]) {
                            val = v[c.key]
                        }

                        if (val) {
                            let w = this.getMinWidth(val)

                            if (!c._w || c._w < w) {
                                c._w = w
                            }
                        }
                    }
                }

                if (!c._w) {
                    c._w = "200px"
                } else if (typeof c._w === "number") {
                    c._w = c._w + "px"
                }
            }
        },

        /**
         * 获取最小宽度值
         */
        getMinWidth(text) {
            // 非字符串则转换为字符串格式
            if (typeof text !== "string") text = String(text)
            // HTML 标签不处理，默认 200 px
            if (/^<.*\/.*>$/.test(text)) return 200
            // 普通字符 15 px，中文加多 20 px/字
            let size = text.length * 15 + (text.match(/[\u4E00-\u9FA5]/g)?.length || 0) * 20
            // 最小宽度为 80, 最大宽度为 1000
            return size > 80 ? (size > 1000 ? 1000 : size) : 80
        },

        /**
         * 监听页面大小变化
         */
        onChangePageSize(evt) {
            this.req?.setIndex(this.currentPage, evt, this.currentPage === 1)
        },

        /**
         * 监听页码变化
         */
        onChangePage(evt) {
            this.req?.setIndex(evt)
        },

        /**
         * 获取单元格值
         */
        getValue(col, row) {
            var v = Utils.getStringObject(row, col.key || col.title)

            if (!v && col.render) {
                v = col.render(h, { row })
            }

            return typeof v === "number" ? v : v || "-"
        },

        /**
         * 获取单元格样式
         */
        getHighlightColor(col, row) {
            let v = this.getValue(col, row)

            if (typeof v === "number" && col.highlight && v > 0) {
                return this.color
            }

            return "#333333"
        },

        /**
         * 监听排序变化
         */
        onChangeSort(col) {
            this.$set(col, "sort", col.sort === "desc" ? "asc" : "desc")
            // 处理数据
            this.onProcessData()
        },

        /**
         * 监听选择器变化事件
         */
        onChangeSelector(value, item) {
            let s = this.selecteds
            let idx = s.findIndex(v => v._id === item._id)

            if (idx > -1) {
                s.splice(idx, 1)
            } else {
                s.push(item)
            }
        },

        /**
         * 监听全选
         */
        onChangeAllSelector() {
            if (this.tableData.length === this.selecteds.length) {
                return (this.selecteds = [])
            }

            this.selecteds = [].concat(this.tableData)
        },

        /**
         * 刷新
         */
        refresh() {
            this.req?.refresh()
        },

        search(data) {
            this.req?.setData(data)
        },

        reset() {
            this.req?.reset()
        },

        /**
         * 清空勾选
         */
        clearSelecteds() {
            this.selecteds = []
        },

        keyword(t) {
            if (!t) {
                this.tableData = this.copyTableData || this.tableData
                return
            }

            if (!this.copyTableData) {
                this.copyTableData = this.tableData
            }

            const result = []

            Utils.each<obj>(this.copyTableData, l => {
                Utils.eachObj(l, (k, v) => {
                    if (v && String(v).indexOf(t) > -1 && !result.includes(l)) {
                        result.push(l)
                    }
                })
            })

            this.tableData = result
        },
    },
}
