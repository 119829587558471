import Cache from "@/module/cache/cache"

export default class Employee {
    private static _employee: obj = Cache.get("userinfo")

    public static get() {
        return this._employee
    }

    public static getUuid() {
        return this._employee?.uuid
    }

    public static isAdmin() {
        return this._employee?.grade?.indexOf("admin")
    }

    private static main = (() => {
        Cache.on("userinfo", (data: obj) => {
            Employee._employee = data
        })
    })()
}
