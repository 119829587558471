
import Language from "../module/language/language"
import Theme from "../module/theme/theme"
import Utils from "../module/utils/utils"

import elemIcon from "./elem-icon.vue"

export default {
    data() {
        return {
            original: null,
            check: null,
            val: null,
            place_holder: null,

            language: {
                enter: "输入",
                optional: "可选",
            },
        }
    },

    props: {
        type: {
            type: String,
            default: "text",
        },
        max: {
            type: Number,
            default: 255,
        },
        name: String,
        title: String,
        // 校验类型
        verify: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        value: {
            type: null,
            default: "",
        },
    },

    components: {
        elemIcon,
    },

    watch: {
        val(value: any) {
            if (value) {
                this.check = Utils.verify(this.verify, value)
            } else {
                this.check = null
            }
        },
        value: {
            handler(value: any) {
                this.original = this.val = value
            },
            immediate: true,
        },
    },

    mounted() {
        // 处理主题数据
        Theme.processPage(this.$el)
        // 处理语言包数据
        Language.process(this)

        this.place_holder = this.placeholder || `${this.language.enter}${this.title || this.name}${this.required ? "" : "(" + this.language.optional + ")"}`

        this.$refs.input.getValue = this.getValue.bind(this)
        // this.$refs.input.menu = [{
        //     id: "update",
        //     icon: "update",
        //     name: "检查更新"
        // }, {
        //     id: "version",
        //     icon: "about",
        //     name: "版本"
        // }]
    },

    methods: {
        onChange(evt: any) {
            this.$emit("on-change", {
                value: evt.target.value,
                type: "elem-input",
                name: this.name,
                set: (value: string) => {
                    this.val = value
                },
            })
        },

        onInput(evt: any) {
            this.$emit("input-event", {
                value: evt.target.value,
                type: "elem-input",
                name: this.name,
                set: (value: string) => {
                    this.val = value
                },
            })
        },

        getValue() {
            const value = this.val

            if (Utils.isBlank(value) && this.required) {
                this.check = false
            }

            return value
        },
    },
}
