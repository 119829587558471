
import Component, { ComponentMethods } from "../module/component/component"

import elemInputObject from './elem-input-object.vue'

class ElemKeyboardComponent extends ComponentMethods implements ComponentEntity {
    data() {
        return {
            layout: {},
            changeKeyData: null
        }
    }

    components = {
		elemInputObject
	}

    props = {
        name: String,
        required: {
            type: Boolean,
            default: true
        },
        title: String,
        value: Object
    }

    created() {
        if (this.value) {
            this.layout = this.value
        } else {
            this.layout = {
                qwe: this.getInitArray("qwertyuiop[]\\"),
                asd: this.getInitArray("asdfghjkl;'"),
                zxc: this.getInitArray("zxcvbnm,./")
            }
        }
    }

    getInitArray(str: string): string[] {
        var str_arr = str.split("")
        var arr = []

        for (var i = 0; i < str_arr.length; i++) {
            arr.push({ hand: str_arr[i] })
        }

        return arr
    }

    getValue() {
        return this.layout
    }

    judgmentEmpty(arr) {
        for (var i = 0; i < arr.length; i ++) {
            if (!arr[i].key || !arr[i].code) {
                return false;
            }
        }
        return true
    }

    onClickChangeKey(evt, name, idx) {
        this.changeKeyData = {
            name: name,
            idx: idx,
            x: evt.layerX,
            y: evt.layerY,
            keyboard: this.layout[name][idx]
        }
    }

    onChangeCode(evt, data) {
        var e = evt.target || evt.srcElement
        var item = this.layout[data.name][data.idx]
        item.code = Math.round(e.value)
    }

    onChangeKey(evt, data) {
        var e = evt.target || evt.srcElement
        var item = this.layout[data.name][data.idx]
        item.key = e.value
    }
}

export default Component.build(new ElemKeyboardComponent)
