import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "video-page" }
const _hoisted_2 = {
  key: 0,
  class: "video-base"
}
const _hoisted_3 = { class: "video-box" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "operating-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_icon = _resolveComponent("elem-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.display)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("video", {
              class: "video",
              controls: "controls",
              src: _ctx.src,
              controlslist: "nodownload"
            }, null, 8, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: "close-box",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
            }, [
              _createVNode(_component_elem_icon, {
                class: "icon",
                name: "close_blue"
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}