
import Component, { ComponentMethods } from "../module/component/component"

import elemIcon from './elem-icon.vue'

class VideoPage extends ComponentMethods implements ComponentEntity {
    private display: boolean = false

    props = {
        src: String
    }

    components = {
        elemIcon
    }

    watch = {
        src: function(val: obj) {
            !!val && (this.display = true)
        }
    }

    methods = {
        onClose() {
            this.display = false

            this.$emit('close', true);
        }
    }
}

export default Component.build(new VideoPage)
