
import Utils from "@/module/utils/utils"

import elemInputObject from "./elem-input-object.vue"
import elemIcon from "./elem-icon.vue"

export default {
    components: {
        elemInputObject,
        elemIcon,
    },
    data() {
        return {
            labels: [],
            place_holder: null,
        }
    },
    props: {
        required: {
            type: Boolean,
            default: true,
        },
        title: String,
        placeholder: {
            type: String,
            default: "",
        },
        name: String,
        value: Array,
        type: {
            type: String,
            default: "textarea",
        },
    },

    watch: {
        value: {
            handler(val) {
                console.log(val)

                this.labels = val || []
            },
            immediate: true,
        },
    },

    mounted() {
        if (!this.placeholder) {
            this.place_holder = `输入${this.title || this.name}，并按回车键结束${this.required ? "" : "（可选）"}`
        } else {
            this.place_holder = this.placeholder + `，并按回车键结束${this.required ? "" : "（可选）"}`
        }
    },

    methods: {
        onAppendLabel(evt) {
            const input = Utils.getTarget<HTMLInputElement>(evt)
            const value = input.value

            if (value) {
                this.labels.push(value.replace(/[\r\n]/g, ""))
            }

            input.value = ""
        },

        onDeleteLabel(idx) {
            this.labels.splice(idx, 1)
        },

        onBlur(evt) {
            this.onAppendLabel(evt)
        },

        getValue() {
            return this.labels
        },
    },
}
