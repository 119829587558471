import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "empty-icon" }
const _hoisted_2 = {
  key: 0,
  class: "p"
}
const _hoisted_3 = {
  key: 1,
  class: "p"
}
const _hoisted_4 = {
  key: 2,
  class: "prompt"
}
const _hoisted_5 = {
  key: 3,
  class: "prompt"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_icon = _resolveComponent("elem-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "empty-page",
    style: _normalizeStyle({ position : _ctx.type === 'page' ? 'absolute' : 'relative' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_elem_icon, {
        class: "icon",
        name: "empty",
        dark: "empty_dark",
        mode: "widthFix"
      })
    ]),
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.type === 'page' ? _ctx.language.page : _ctx.language.components), 1)),
    (_ctx.prompt)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.prompt), 1))
      : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.language.examine), 1)),
    (_ctx.type === 'page')
      ? (_openBlock(), _createElementBlock("button", {
          key: 4,
          class: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onBack && _ctx.onBack(...args)))
        }, _toDisplayString(_ctx.language.back), 1))
      : _createCommentVNode("", true)
  ], 4))
}