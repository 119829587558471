import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "elem-price-box",
  "dark-class": "elem-price-box-dark"
}
const _hoisted_2 = ["maxlength", "title", "required", "name", "placeholder"]
const _hoisted_3 = { class: "operating-box" }
const _hoisted_4 = { class: "unit-box" }
const _hoisted_5 = { class: "check-box" }
const _hoisted_6 = { class: "icon-box" }
const _hoisted_7 = { class: "icon-box" }
const _hoisted_8 = { class: "menu-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_icon = _resolveComponent("elem-icon")!
  const _component_elem_prompt = _resolveComponent("elem-prompt")!
  const _component_comp_menu = _resolveComponent("comp-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "input",
      maxlength: _ctx.max,
      type: "number",
      class: "input",
      title: _ctx.title,
      required: _ctx.required,
      name: _ctx.name,
      placeholder: _ctx.place_holder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.val]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.type === "yuan" ? "/元" : "/分"), 1),
      _withDirectives(_createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("view", _hoisted_6, [
          _createVNode(_component_elem_icon, {
            src: "static/icon/components/elem-input/",
            name: "correct"
          })
        ], 512), [
          [_vShow, _ctx.check !== null && _ctx.check.isNormal()]
        ]),
        _withDirectives(_createElementVNode("view", _hoisted_7, [
          _createVNode(_component_elem_prompt, {
            title: _ctx.check !== null ? _ctx.check.getMessage() : ''
          }, null, 8, ["title"]),
          _createVNode(_component_elem_icon, {
            src: "static/icon/components/elem-input/",
            name: "error"
          })
        ], 512), [
          [_vShow, _ctx.check !== null && _ctx.check.isBlank()]
        ])
      ], 512), [
        [_vShow, _ctx.check !== null]
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_elem_icon, {
          class: "icon",
          name: "operating"
        }),
        _createVNode(_component_comp_menu, {
          ref: "operatings_menu",
          position: "follow",
          value: _ctx.operatings,
          onSelect: _ctx.onSelectOperating
        }, null, 8, ["value", "onSelect"])
      ])
    ])
  ]))
}