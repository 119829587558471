import Employee from "@/entity/employee"

export default class Menu {
    public static get(): MenuItem[] {
        var menuList: MenuItem[] = [
            {
                icon: "menu_home.svg",
                href: "/#/",
                name: "首页",
                root: "home",
                child: null,
                type: null,
            },
            {
                icon: "menu_data.svg",
                href: null,
                name: "数据管理",
                root: "data",
                child: [
                    {
                        icon: "data.svg",
                        href: "/#/goods",
                        name: "商品管理",
                        root: null,
                        child: null,
                        type: "data",
                    },
                    {
                        icon: "data.svg",
                        href: "/#/meal",
                        name: "套餐管理",
                        root: null,
                        child: null,
                        type: "data",
                    },
                    {
                        icon: "data.svg",
                        href: "/#/driver",
                        name: "司机管理",
                        root: null,
                        child: null,
                        type: "data",
                    },
                    {
                        icon: "data.svg",
                        href: "/#/vehicle",
                        name: "车辆管理",
                        root: null,
                        child: null,
                        type: "data",
                    },
                    {
                        icon: "data.svg",
                        href: "/#/maintaining",
                        name: "维护保养",
                        root: null,
                        child: null,
                        type: "data",
                    },
                ],
                type: null,
            },
            {
                icon: "finance.svg",
                href: null,
                name: "财务管理",
                root: "data",
                child: [
                    {
                        icon: "data.svg",
                        href: "/#/order",
                        name: "订单管理",
                        root: null,
                        child: null,
                        type: "data",
                    },
                    {
                        icon: "data.svg",
                        href: "/#/income",
                        name: "收入明细",
                        root: null,
                        child: null,
                        type: "data",
                    },
                    {
                        icon: "data.svg",
                        href: "/#/expenses",
                        name: "支出管理",
                        root: null,
                        child: null,
                        type: "data",
                    },
                    {
                        icon: "data.svg",
                        href: "/#/loans",
                        name: "借款登记",
                        root: null,
                        child: null,
                        type: "data",
                    },
                ],
            },
        ]

        if (Employee.isAdmin()) {
            menuList.push(
                {
                    icon: "approval.svg",
                    name: "审批中心",
                    root: "approval",
                    child: [
                        {
                            icon: "data.svg",
                            href: "/#/approval/discount",
                            name: "优惠申请审批",
                            type: "data",
                        },
                        {
                            icon: "data.svg",
                            href: "/#/loans?type=approval",
                            name: "借款申请审批",
                            type: "data",
                        },
                        {
                            icon: "data.svg",
                            href: "/#/invoice",
                            name: "发票申请审批",
                            type: "data",
                        },
                        {
                            icon: "data.svg",
                            href: "/#/approval/vehicle_change?t=VEHICLE_CHANGE",
                            name: "车辆套餐变更审批",
                            type: "data",
                        },
                        {
                            icon: "data.svg",
                            href: "/#/approval/vehicle_change?t=VEHICLE_TRANSFER",
                            name: "车辆转出审批",
                            type: "data",
                        },
                        {
                            icon: "data.svg",
                            href: "/#/approval/vehicle_change?t=VEHICLE_ARCHIVE",
                            name: "车辆归档审批",
                            type: "data",
                        },
                    ],
                },
                {
                    icon: "menu_user.svg",
                    href: "/#/employee",
                    name: "员工管理",
                    root: "employee",
                    child: null,
                    type: null,
                },
                {
                    icon: "menu_message.svg",
                    href: "/#/message",
                    name: "通知中心",
                    root: "message",
                    child: null,
                    type: null,
                }
                // {
                //     icon: "config_white.svg",
                //     href: "/#/system",
                //     name: "系统管理",
                //     root: "system",
                //     child: [
                //         {
                //             icon: "data.svg",
                //             href: "/#/discount",
                //             name: "平台设置",
                //             type: "data",
                //         },
                //     ],
                //     type: null,
                // }
            )
        }

        return menuList
    }
}

export interface MenuItem {
    icon: string
    href?: string
    root?: string
    component?: string
    name?: string
    child?: MenuItem[]
    type?: string
}
