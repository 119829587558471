
import Utils from "../module/utils/utils"
import elemIcon from "./elem-icon.vue"
import elemInputObject from "./elem-input-object.vue"
import Theme from "../module/theme/theme"
import Request from "@/module/request/request"

export default {
    name: "elem-select",

    data() {
        return {
            data_list: null,
            unfold: false,
            val: null,
            val_text: null,
            search: null,
            ph: "",
            label: [],
        }
    },

    props: {
        datas: {
            type: Array,
            required: false,
        },
        // 远程搜索接口配置
        remotely: {
            type: Object,
            required: false,
        },
        required: {
            type: Boolean,
            default: true,
        },
        title: String,
        placeholder: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "switch",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: new Object(),
        },
        value: [Object, Array, String, Number],
        // 禁用
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value: {
            handler(value) {
                if (Utils.isExist(value)) {
                    if (this.multiple) {
                        Utils.each<obj>(value, v => {
                            if (typeof v === "object" && v.uuid) {
                                v = v.uuid
                            }

                            // Can't choose repeatedly
                            if (this.val && this.val.indexOf(v) > -1) {
                                return
                            }

                            this.val ? this.val.push(v) : (this.val = [v])

                            Utils.each<obj>(
                                this.datas,
                                v => {
                                    this.label.push(v.value)
                                },
                                c => c.id == v
                            )
                        })
                    } else {
                        if (typeof value === "object" && value.uuid) {
                            value = value.uuid
                        }

                        this.val = value

                        Utils.each<obj>(
                            this.datas,
                            v => {
                                this.val_text = v.value
                            },
                            c => c.id == value
                        )
                    }
                } else {
                    this.val = null
                    this.label = []
                    this.val_text = null
                }
            },
            immediate: true,
        },

        val: function (value, oldValue) {
            this.onChangeData(value, oldValue)
        },
    },

    components: {
        elemIcon,
        elemInputObject,
    },

    created() {
        if (this.datas) {
            const data_list = Utils.copy(this.datas)

            Utils.each(
                data_list,
                (v, i) => {
                    return { id: i, value: v }
                },
                c => "string" === typeof c
            )

            this.data_list = data_list
        }
    },

    mounted() {
        Theme.processPage(this.$el)

        this.ph = this.placeholder ? this.placeholder : `请选择${this.required ? "" : "（可选）"}`
    },

    methods: {
        onChangeData(value, oldValue) {
            this.$emit("change", {
                value: value,
                type: "elem-select",
                name: this.name,
                restore: () => {
                    this.val = oldValue
                },
            })
        },

        onInputFocus() {
            this.unfold = true
        },

        onInputBlur() {
            setTimeout(() => {
                this.unfold = false
            }, 100)
        },

        onSelect(id, val) {
            if (this.multiple) {
                // Can't choose repeatedly
                if (this.val && this.val.indexOf(id) > -1) {
                    return
                }

                this.val ? this.val.push(id) : (this.val = [id])
                this.label.push(val)
                this.val_text = ""
            } else {
                this.val = id
                this.val_text = val
            }

            setTimeout(() => {
                this.search = null
            }, 500)
        },

        onSearchSelect(evt) {
            const val = (evt.target || evt.srcElement).value

            if (this.config.remotely) {
                this.onSearch(val)
            } else {
                this.val = null
                this.search = val
            }
        },

        onDeleteLabel(idx) {
            if (this.val.length > 1) {
                this.val.splice(idx, 1)
            } else {
                this.val = null
            }

            this.label.splice(idx, 1)
        },

        onSearch(val) {
            if (!val) return (this.data_list = null)

            clearTimeout(this.sst)

            this.sst = setTimeout(() => {
                this.showLoadingSign = true

                Request.get<obj[]>(this.config.remotely.api, {
                    [this.remotely.search]: val,
                })
                    .then(res => {
                        const arr = []

                        for (let i = 0, d = res; i < d.length; i++) {
                            const e = d[i]

                            arr.push({
                                id: e[this.remotely.id],
                                value: e[this.remotely.value],
                            })
                        }

                        this.data_list = arr
                    })
                    .finally(() => {
                        this.showLoadingSign = false
                    })
            }, 300)
        },
    },
}
