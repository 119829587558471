
import Queue from "@/module/queue/queue"
import Language from "../module/language/language"
import Theme from "../module/theme/theme"
import Utils from "../module/utils/utils"

import Request from "@/module/request/request"

export default {
    data() {
        return {
            id: Utils.getUuid(),
            check: null,
            val: null,
            place_holder: null,
            // 验证码信息
            codeInfo: null,

            language: {
                enter: "输入",
                optional: "可选",
            },

            // 倒计时
            countdown: 0,
        }
    },

    props: {
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        nature: String,
        // 类型，code: 验证码  phone: 手机验证码
        type: {
            type: String,
            default: "code",
        },
        // 实体名称
        entity: {
            type: String,
            required: false,
        },
        token: {
            type: String,
            required: false,
        },
        phone: {
            type: String,
            required: false,
        },
        validate: {
            type: String,
            required: false,
        },
    },

    watch: {
        val(value: any) {
            if (value) {
                this.check = Utils.verify(this.verify, value)
            } else {
                this.check = null
            }
        },

        token: {
            handler() {
                this.countdownTimer()
            },
            immediate: true,
        },

        validate: {
            handler(v) {
                this.verify_id = v
            },
            immediate: true,
        },
    },

    mounted() {
        // 处理主题数据
        Theme.processPage(this.$el)
        // 处理语言包数据
        Language.process(this)

        this.place_holder = this.placeholder || `${this.language.enter}${this.title || this.name}${this.required ? "" : "(" + this.language.optional + ")"}`

        this.$refs.input.getValue = this.getValue.bind(this)

        if (this.type === "phone") {
            this.countdownTimer()
        } else if (this.type === "code") {
            this.getValidateCode()
        }
    },

    methods: {
        /**
         * 刷新验证码
         */
        refresh() {
            this.getValidateCode()
            this.val = null
        },

        /** 获取验证码数据 */
        getValidateCode() {
            Queue.single("GetValidateCode" + this.id, next => {
                this.codeInfo = null

                Request.get<obj>(
                    "WEB://ValidateCode/GetCode",
                    {
                        nature: this.nature,
                    },
                    {
                        hideLoading: true,
                        onComplete: () => {
                            next()
                        },
                    }
                ).then(res => {
                    this.codeInfo = res
                })
            })
        },

        onChange(evt: any) {
            var value: any

            switch (this.type) {
                case "code":
                    value = {
                        text: evt.target.value,
                        validate: this.codeInfo.id,
                    }
                    break
                case "phone":
                    value = {
                        text: evt.target.value,
                        validate: this.verify_id,
                    }
                    break
                default:
                    value = evt.target.value
                    break
            }

            this.$emit("on-change", {
                value: value,
                type: "elem-verification",
                name: this.name,
            })
        },

        getValue() {
            const value = this.val

            if (Utils.isBlank(value) && this.required) {
                this.check = false
            }

            return {
                text: value,
                validate: this.codeInfo.id,
            }
        },

        // 发送手机验证码
        sendPhoneCode() {
            Request.post<obj>(`WEB://${this.entity}/SendRegisterCodeAgain`, {
                token: this.token,
                phone: this.phone,
            }).then(res => {
                this.verify_id = res.id
                this.countdownTimer()
            })
        },

        // 倒计时
        countdownTimer() {
            this.countdown = 60

            const timer = setInterval(() => {
                this.countdown--

                if (this.countdown <= 0) {
                    clearInterval(timer)
                }
            }, 1000)
        },
    },
}
