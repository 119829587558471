import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_input_object = _resolveComponent("elem-input-object")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["elem-switch-box", ['elem-switch-box-size-' + $props.size, { 'elem-switch-box-dark': $data.theme === 'dark' }]])
  }, [
    _createVNode(_component_elem_input_object, {
      name: $props.name,
      value: $data.val,
      "is-required": $props.required
    }, null, 8, ["name", "value", "is-required"]),
    _createElementVNode("div", {
      class: _normalizeClass(["switch-box", $data.val ? 'switch-on' : 'switch-off']),
      style: _normalizeStyle({ 'border-radius': $data.radius + 'px' })
    }, [
      _createElementVNode("div", {
        class: "switch-btn on-btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onChangeSwitch(true)))
      }, _toDisplayString($props.on), 1),
      _createElementVNode("div", {
        class: "switch-btn off-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.onChangeSwitch(false)))
      }, _toDisplayString($props.off), 1),
      _createElementVNode("div", {
        class: "block",
        style: _normalizeStyle({ 'border-radius': $data.radius + 'px' })
      }, null, 4)
    ], 6)
  ], 2))
}