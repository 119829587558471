import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5befad42"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_form_item = _resolveComponent("elem-form-item")!

  return (_openBlock(), _createElementBlock("div", {
    class: "form-box",
    id: $data.id
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.structure, (item, idx) => {
      return (_openBlock(), _createBlock(_component_elem_form_item, {
        key: idx,
        index: idx,
        name: item.name,
        data: item,
        value: $data.formdata[item.name],
        onOnChange: $options.onChangeValue,
        width: "100%"
      }, null, 8, ["index", "name", "data", "value", "onOnChange"]))
    }), 128))
  ], 8, _hoisted_1))
}