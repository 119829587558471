<template>
    <DatePicker class="date-picker" dark-class="date-picker-dark" :name="name" v-model="val" :type="type" placeholder="选择日期" :format="data_format" :disabled="disabled" transfer />
</template>

<script>
import Theme from "@/module/theme/theme"
import DateUtils from "@/module/utils/date"

export default {
    data() {
        return {
            val: null,
            data_format: undefined,
        }
    },

    props: {
        value: {
            type: String || Date,
            required: false,
        },
        // 类型
        type: {
            type: String,
            default: "date",
        },
        // 字段名
        name: String,
        // 禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        // 必选
        required: {
            type: Boolean,
            default: true,
        },
        // 占位符
        placeholder: {
            type: String,
            default: "",
        },
    },

    watch: {
        val(v) {
            if (!v) {
                this.format = null
            } else if (v instanceof Array) {
                this.format = v.map(i => DateUtils.formatDate(i, "yyyy-MM-dd" + (~this.type.indexOf("time") ? " hh:mm:ss" : "")))
            } else {
                this.format = DateUtils.formatDate(v, "yyyy-MM-dd" + (~this.type.indexOf("time") ? " hh:mm:ss" : ""))
            }

            this.$emit("on-change", {
                tag: "ElemDate",
                value: this.format,
                name: this.name,
            })
        },

        value: {
            handler(v) {
                console.log(v)

                if (!v) {
                    return this.val && (this.val = null)
                }

                if (v === this.format) return

                if (typeof v === "string") {
                    // 校验日期格式是否正确
                    // if (!/^[0-9]+(-|\/)[0-9]+(-|\/)[0-9]+$/.test(v)) return
                    // new 日期对象
                    v = new Date(v)
                }

                this.val = v
            },
            immediate: true,
        },

        type: {
            handler(v) {
                switch (v) {
                    case "datetimerange":
                        this.data_format = "yyyy-MM-dd HH:mm"
                        break
                }
            },
            immediate: true,
        },
    },

    mounted() {
        // 处理主题数据
        Theme.processPage(this.$el)
    },
}
</script>

<style lang="less" scoped>
@import (reference) "/src/style/utils.less";
@import (reference) "/src/style/color.less";

.date-picker {
    width: 100%;

    :deep(.ivu-input-suffix) {
        display: flex;
        align-items: center;
    }

    :deep(.ivu-input) {
        height: 45px;
        padding: 0 50px 0 12px;
        border-radius: 6px;

        &:hover,
        &:focus {
            border-color: #b3b3b3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
    }
}

.elem-date-box {
    position: relative;
    width: 100%;
    height: 45px;

    .input {
        position: relative;
        height: 45px;
        padding: 0 50px 0 12px;
        font-size: 14px;
        z-index: 10;
        box-sizing: border-box;
        border-radius: 6px;
        transition: all 0.3s ease;
        background: #fff;

        .border;

        &:hover,
        &:focus {
            border-color: #b3b3b3;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
    }

    .icon-box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 15;

        .icon {
            width: 25px;
            height: 25px;
        }
    }
}

.date-picker-dark {
    :deep(.ivu-input) {
        background: #252a31;
        border-color: #3b3b3b;
    }
}
</style>
