
import Utils from "../module/utils/utils"

import elemIcon from "./elem-icon.vue"
import compMenu from "./comp-menu.vue"
import Component, { ComponentMethods } from "@/module/component/component"
import Status from "@/module/status/status"
import StringUtils from "@/module/utils/string_utils"

const md5 = require("js-md5")

class ElemPasswordComponent extends ComponentMethods implements ComponentEntity {
    private check: boolean = null
    private val: string = null
    private place_holder: string = null
    private hidden: boolean = true
    private change: boolean = false

    public language = {
        enter: "输入",
        optional: "可选",
    }

    private operatings = [
        {
            title: "状态设置",
            prompt: "仅限显示输入的新密码",
            sub: [
                {
                    id: "Display",
                    icon: "display",
                    name: "显示密码",
                },
                {
                    id: "Hide",
                    icon: "hide",
                    name: "隐藏密码",
                },
            ],
        },
        {
            title: "恢复",
            prompt: "恢复至变更密码前，仅限修改状态恢复",
            sub: [
                {
                    id: "Restore",
                    icon: "restore",
                    disable: true,
                    name: "恢复密码",
                },
            ],
        },
    ]

    props = {
        config: {
            type: Object,
            default: {
                encryption: true,
            },
        },
        max: {
            type: Number,
            default: 255,
        },
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        value: null,
        // 是否需要校验密码格式
        verify: {
            type: Boolean,
            default: true,
        },
    }

    components = {
        elemIcon,
        compMenu,
    }

    watch = {
        value: {
            handler(value: string | obj) {
                if (Utils.isBlank(value)) return (this.val = null)
                if (this.val === value) return

                this.isEdit = true
                // 赋值初始值
                if (typeof value === "string") {
                    this.original = this.val = value
                } else {
                    this.original = this.val = value.value
                }
                // 恢复密码功能设置为可用
                this.$refs.operatings_menu.onChangeDisable("Restore", false)
            },
            immediate: true,
        },
        val(value: string, oldValue: string) {
            if (this.isEdit && !this.initial) {
                this.check = true
                this.initial = true
                this.$refs.operatings_menu.onChangeDisable("Display", true)
                return
            }

            if (!this.change && this.original === oldValue) {
                if (oldValue?.length < value.length) {
                    const d = StringUtils.different(oldValue, value)
                    this.val = d.value
                } else if (value.length !== 1) {
                    this.val = ""
                    this.check = null
                }

                this.$refs.operatings_menu.onChangeDisable("Display", false)
            } else {
                if (value) {
                    this.check = this.verify ? Utils.verify("password", value) : true
                } else {
                    this.check = null
                }
            }

            this.change = true
        },
    }

    mounted() {
        // if (Utils.isExist(this.value)) {
        //     this.isEdit = true
        //     // 赋值初始值
        //     if (typeof this.value === "string") {
        //         this.original = this.val = this.value
        //     } else {
        //         this.original = this.val = this.value.value
        //     }
        //     // 恢复密码功能设置为可用
        //     this.$refs.operatings_menu.onChangeDisable("Restore", false)
        // }

        this.place_holder = this.placeholder || `${this.language.enter}${this.title || this.name}${this.required ? "" : "(" + this.language.optional + ")"}`

        this.$refs.input.getValue = this.getValue.bind(this)
    }

    onChange(evt: obj) {
        this.$emit("change", {
            value: evt.target.value,
            type: "elem-input",
            name: this.name,
            set: (value: string) => {
                this.val = value
            },
        })
    }

    onInput(evt: obj) {
        this.$emit("input-event", {
            value: evt.target.value,
            type: "elem-input",
            name: this.name,
            set: (value: string) => {
                this.val = value
            },
        })
    }

    getValue(): Status<any> {
        var value = this.val

        if (Utils.isBlank(value)) {
            if (!this.required) {
                return new Status(200).setData(null)
            }

            this.check = false
            return Status.of(400).setMessage(`${this.title} 不能为空`)
        }

        if (!this.check) {
            return Status.of(400).setMessage(`${this.title} 格式不正确`)
        }

        // 是否加密
        if (this.config?.encryption !== false) {
            // 非初始值
            if (this.change) {
                // MD5 加密
                value = md5(value)
            }
        }

        // 返回密码实体
        if (this.config.entity) {
            return new Status(200).setData(Object.assign({}, this.value, { value }))
        }

        // 返回字符串密码
        return new Status(200).setData(value)
    }

    onSelectOperating(evt: obj) {
        switch (evt.value) {
            case "Hide":
                this.hidden = true
                break
            case "Display":
                if (this.original && this.original === this.val && !this.change) {
                    return
                }
                this.hidden = false
                break
            case "Restore":
                this.change = this.initial = false
                this.hidden = true
                this.val = this.original
        }
    }
}

export default Component.build(new ElemPasswordComponent())
