
import Utils from "../module/utils/utils"

export default {
    data() {
        return {
            focus: false,
            arrow_left: "15px",
        }
    },

    props: {
        el: null,
        data: Object,
        all: {
            type: Boolean,
            default: true,
        },
        direction: {
            type: String,
            default: "bottom",
        },
    },

    mounted() {
        var child: HTMLElement

        if ("string" === typeof this.el) {
            child = Utils.getElement(this.el)
        } else {
            child = this.el
        }

        // 箭头左侧定位
        this.arrow_left = child.offsetWidth / 2 + "px"

        child.tabIndex = 0
        child.style.cursor = "pointer"

        if (!child.style.position) {
            child.style.position = "relative"
        }

        child.onfocus = () => {
            this.focus = true
        }

        child.onblur = () => {
            this.focus = false
        }

        this.child = child
    },
    methods: {
        onSelect(data?: any, idx?: number | string) {
            // 默认为 “所有” 选项
            if (Utils.isBlank(data)) {
                data = {
                    title: "所有",
                    value: null,
                }
            }

            if (!(data instanceof Object)) {
                data = {
                    value: data,
                }
            }

            data.index = idx

            this.$emit("select", {
                value: data,
                type: "elem-options",
                name: this.name,
            })

            // 触发失焦事件
            this.child.blur()
        },
    },
}
