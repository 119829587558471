
import Utils from "@/module/utils/utils"
import Message from "@/module/interactive/message"
import FileUtil from "@/module/file/file_util"
import Loading from "@/module/loading/loading"
import Request from "@/module/request/request"
import Crop from "@/module/crop/crop"
import Theme from "@/module/theme/theme"
import Language from "@/module/language/language"

import elemInputObject from "./elem-input-object.vue"
import ElemIcon from "./elem-icon.vue"

export default {
    props: {
        value: {
            type: Object,
            default: new Array(),
        },
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        multi: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: "image",
        },
        size: String,
        // 归类名称（文件名 = 模板名称 + 源文件名）
        belong: {
            type: String,
            default: "",
        },
        folder: {
            type: String,
            default: "",
        },
        accept: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            files: [],
            types: {
                image: {
                    name: "图片",
                    accept: "image/*",
                    url: "WEB://Resource/Image/Upload",
                },
                video: {
                    name: "视频",
                    accept: "video/*",
                    url: "WEB://Resource/Video/Upload",
                },
                audio: {
                    name: "音频",
                    accept: "audio/*",
                    url: "WEB://Resource/Audio/Upload",
                },
                file: {
                    name: "文件",
                    accept: "",
                    url: "WEB://Resource/File/Upload",
                },
            },
        }
    },

    components: {
        ElemIcon,
        elemInputObject,
    },

    watch: {
        value: {
            handler: function (value) {
                if (!value) return

                if ("string" === typeof value) {
                    value = [
                        {
                            src: value,
                        },
                    ]
                } else if (!(value instanceof Array)) {
                    value = [value]
                }

                var files = []

                Utils.each<obj>(value, v => {
                    files.push({
                        id: Utils.getUuid(),
                        ...v,
                    })
                })

                this.files = files
            },
            immediate: true,
        },
    },

    mounted() {
        var dropbox = this.$refs.upload_drag

        dropbox.addEventListener("drop", this.enentDrop, false)

        dropbox.addEventListener("dragleave", e => {
            e.stopPropagation()
            e.preventDefault()
        })

        dropbox.addEventListener("dragenter", e => {
            e.stopPropagation()
            e.preventDefault()
        })

        dropbox.addEventListener("dragover", e => {
            e.stopPropagation()
            e.preventDefault()
        })

        // 处理主题数据
        Theme.processPage(this.$el)
        // 处理语言包数据
        Language.process(this)
        // 触发加载事件
        this.$emit("on-load", {
            tag: "elem-upload",
            name: this.name,
            value: this,
        })
    },

    methods: {
        enentDrop(evt) {
            evt.stopPropagation()
            evt.preventDefault()

            this.processFiles(evt.dataTransfer.files)
        },

        onClickUpload() {
            this.$refs.upload_input.click()
        },

        onSelectFile(evt) {
            this.processFiles((evt.target || evt.srcElement).files)
        },

        async processFiles(files) {
            for (let i = 0; i < files.length; i++) {
                await this.processFile(files[i])
                // var file = files[i]
            }

            // 清空 input 值
            this.$refs.upload_input.value = null
        },

        editUploadDisable() {
            Message.error("编辑功能仅支持图片格式")
        },

        processFile(file: File): Promise<void> {
            return new Promise((resolve, reject) => {
                const config = this.types[this.type]

                if (!new RegExp(config.accept).test(file.type)) {
                    Message.error(`不支持当前文件类型，仅支持 ${config.name} 类型`)

                    return reject()
                }

                var reader = new FileReader()
                reader.readAsDataURL(file)

                reader.onerror = err => {
                    Message.error("文件加载失败")

                    reject(err)
                }

                reader.onload = async () => {
                    if (!this.multi && this.files.length > 0) {
                        Message.info("最多只能上传一个文件")

                        reject()

                        return
                    }

                    var res = reader.result
                    var blob

                    if (this.type === "image" && Utils.isExist(this.size)) {
                        let data = await this.editImageByFile(res, this.size)

                        res = data.dataURL

                        if (data.blob) {
                            blob = data.blob
                        }
                    }

                    this.files.push({
                        id: Utils.getUuid(),
                        src: res,
                        name: file.name,

                        ...(blob ? { blob } : { file }),
                    })

                    resolve()
                }
            })
        },

        moveUpload: function (id: string, type) {
            Utils.each<obj>(this.files, v => (v.id === id ? type : null))
        },

        editImageByFile(base64, size): Promise<obj> {
            return new Promise((resolve, reject) => {
                const sizes = size.replace(/\s+/g, "").split("*")

                let w = sizes[0]
                let h = sizes[1]

                const image = new Image()

                image.onload = async () => {
                    // 宽高与指定宽高相等
                    if (image.width < w || image.height < h) {
                        let msg = "图片分辨率最低为：" + this.size

                        // 显示错误提示
                        Message.error(msg)

                        reject(msg)
                    } else if (image.width === w && image.height === h) {
                        // resolve({ dataURL: base64, file })
                    } else {
                        // 判断是否初始化裁剪资源文件
                        if (!this.initializedCropResource) {
                            await this.initCropResource()
                        }

                        new Crop(base64, {
                            aspectRatio: w / h,
                            width: w,
                            height: h,
                        })
                            .onError(reject)
                            .onConfirm(data => {
                                resolve(data)
                            })
                            .build()
                    }
                }

                image.onerror = reject

                image.src = base64
            })
        },

        async editImageById(id) {
            // 判断是否初始化裁剪资源文件
            if (!this.initializedCropResource) {
                await this.initCropResource()
            }

            var config

            if (Utils.isExist(this.size)) {
                const sizes = this.size.replace(/\s+/g, "").split("*")

                let w = sizes[0]
                let h = sizes[1]

                config = {
                    aspectRatio: w / h,
                    width: w,
                    height: h,
                }
            }

            Utils.each<obj>(
                this.files,
                v => {
                    if (v.crop) v.crop.show()
                    else
                        v.crop = new Crop(v.preview || v.url || v.src, config)
                            .onError(function (err) {
                                Message.error(err)
                            })
                            .onConfirm(data => {
                                v.blob = data.blob
                                v.src = data.dataURL

                                v.url = null
                            })
                            .build()
                },
                v => v.id === id
            )
        },

        deleteUpload: function (id) {
            Message.info("确认删除该文件吗？", true)
                .onConfirm(() => {
                    Utils.each<obj>(this.files, function (d) {
                        return d.id === id ? "delete" : null
                    })
                })
                .build()
        },

        // 初始化裁剪资源文件
        initCropResource(): Promise<void> {
            return new Promise((resolve, reject) => {
                Loading.show()

                Promise.all([
                    FileUtil.style("/modules/crop/cropper.min.css"),
                    FileUtil.style("/modules/crop/crop.css"),
                    FileUtil.script("/modules/crop/cropper.min.js"),
                    FileUtil.script("/modules/crop/corp.js"),
                ])
                    .then(() => {
                        this.initializedCropResource = true

                        resolve()

                        Loading.hide()
                    })
                    .catch(reject)
            })
        },

        getFiles: function () {
            return new Promise((resolve, reject) => {
                const files = this.files

                if (!this.required && files.length <= 0) {
                    return resolve(null)
                }

                if (files.length <= 0) {
                    const msg = this.title + " 不能为空"

                    // Show Msg
                    Message.error(msg)

                    // Callback Error
                    return reject(msg)
                }

                var path = []
                var formData = new FormData()

                Utils.each<obj>(files, function (v, i) {
                    if (v.blob) {
                        formData.append("file", v.blob, v.name)
                    } else if (v.file) {
                        formData.append("file", v.file, v.name)
                    } else if (v.src) {
                        path.push({ idx: i, ...v })
                    }
                })

                if (formData.getAll("file").length <= 0) {
                    if (!this.multi) {
                        path = path[0]
                    }

                    resolve(path)

                    return
                }

                // 指定归类名称
                formData.append("name", this.belong)
                // 指定文件夹
                formData.append("folder", this.folder)

                Request.post<obj[]>(this.types[this.type].url, formData, {
                    onFail: function (err) {
                        Message.error(err.responseJSON.message)

                        reject(err.responseJSON.message)
                    },

                    onProgress(e) {
                        console.log(e)
                    },
                }).then(res => {
                    resolve(res)
                })
            })
        },
    },
}
