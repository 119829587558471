
import Component, { ComponentMethods } from "@/module/component/component"
import Request from "@/module/request/request"
import Utils from "@/module/utils/utils"
import Message from "@/module/interactive/message"

import elemIcon from "@/components/elem-icon.vue"
import elemFormItem from "@/components/elem-form-item.vue"

class FormView extends ComponentMethods implements ComponentEntity {
    private value: obj = {}

    private type: "create" | "edit" | "config" = "create"

    private step_map: obj = {}

    public components = {
        elemIcon,
        elemFormItem,
    }

    props = {
        // 表单结构
        structure: Array,
        // 提交数据接口
        submitApi: String,
        // 修改数据接口
        editApi: {
            type: String,
            required: false,
        },
        // 详情接口
        detailApi: {
            type: String,
            required: false,
        },
        // 提交表单的前置事件
        submitBefore: {
            type: Function,
            required: false,
        },
        // 数据 UUID
        uuid: {
            type: String,
            required: false,
        },
    }

    watch = {
        uuid(value: string) {
            if (value && this.detailApi) {
                // 修改为编辑模式
                this.type = "edit"
                // 获取详情
                this.getDetail(value)
            } else {
                // 修改为新建模式
                this.type = "create"
                // 清空数据
                this.value = {}
            }
        },
    }

    activated() {
        if (this.uuid) {
            // 修改为编辑模式
            this.type = "edit"
            // 获取详情
            this.getDetail(this.uuid)
        } else {
            // 修改为新建模式
            this.type = "create"
        }
    }

    deactivated() {
        // 清空数据
        this.value = {}
        // 刷新
        this.$forceUpdate()
    }

    async onLoad(): Promise<void> {
        // const name = (this.entity_name = param.name)
        // const type = (this.type = param.type)
        // const config = (this.pageConfig = await Href.getPage(name))
        // if (config.type === "config") {
        //     this.configName = name
        //     Request.get<obj>(`ADMIN://Setting/Config/GetCreateData`, { name: name }).then(async res => {
        //         // 设置标题
        //         this.setTitle(config.title, "config")
        //         this.value = await Request.get<obj>(`ADMIN://Setting/Config/GetData`, { name: name })
        //         this.formConfig = res
        //     })
        // } else {
        //     Request.get<obj>(`ADMIN://${name}/GetCreateData`).then(async res => {
        //         // 设置标题
        //         this.setTitle(config.title)
        //         // 是否为编辑模式
        //         if (type === "edit") {
        //             // 获取实体
        //             this.value = await Request.get<obj>(`ADMIN://${name}/FindEntity`, { uuid: param.i })
        //         }
        //         this.formConfig = res
        //     })
        // }
    }

    setFormData(obj: obj) {
        Object.keys(obj).forEach(key => {
            this.value[key] = obj[key]
        })
    }

    /**
     * 获取详情
     */
    getDetail(uuid: string) {
        Request.get<obj>(this.detailApi, { uuid }).then(res => {
            this.value = res
            // 更新
            this.$forceUpdate()
        })
    }

    onChangeValue(evt: ElemEvent<obj>) {
        if (evt.type === "upload") {
            if (!this.resources) {
                this.resources = []
            }

            this.resources.push({
                name: evt.name,
                elem: evt.value,
            })

            return
        }

        this.value[evt.name] = evt.value
    }

    /** 监听滚动到指定模块 */
    onScrollToStepBox(id: string): void {
        Utils.getElements<HTMLDivElement>(["#StepBox", "#" + id], (b, i) => {
            b.scrollTo({
                top: i.offsetTop - 80,
                left: 0,
                behavior: "smooth",
            })
        })
    }

    /**
     * 获取序号
     */
    getStepNumber(idx: number) {
        const es = Utils.getElementAll(".step-base")

        for (let i = 0, leng = es.length; i < leng; i++) {
            if (es[i].classList.contains(".step-item-" + idx)) {
                return i + 1
            }
        }

        return idx + 1
    }

    async submit() {
        const status = await Utils.getFormData("#CreateForm")

        if (status.isBlank()) {
            return Message.error(status.getMessage())
        }

        var data = {
            ...this.getParams(),
            ...status.getData(),
        }

        if (this.type === "edit") {
            data.uuid = this.value?.uuid
        }

        // 判断并触发父组件提交数据的前置事件
        if (this.submitBefore) {
            var cb = this.submitBefore(data, this.type)

            if (typeof cb !== "undefined") {
                // 判断是否为异步
                if (cb instanceof Promise) {
                    cb = await cb
                }

                if (cb === false) return

                // 使用前置事件返回的数据
                data = cb
            }
        }

        Request.post((this.type === "edit" ? this.editApi : null) || this.submitApi, data, {
            json: true,
        }).then(() => {
            Message.success(this.getTypeName(), true)
                .onTimeout(() => {
                    // 返回上一步
                    if (this.type !== "config") {
                        this.onBack()
                    }
                })
                .build()
        })
    }

    getTypeName() {
        switch (this.type) {
            case "create":
                return "添加成功"
            case "edit":
                return "修改成功"
            case "config":
                return "配置成功"
        }
    }

    private getStepMap(name: string): obj[] {
        if (!this.step_map[name]) {
            let size = this.value && this.value[name] && this.value[name].length > 0 ? this.value[name].length : 1
            var arr = []

            for (let i = 0; i < size; i++) {
                arr.push({
                    key_id: Utils.getUuid(),
                })
            }

            this.step_map[name] = arr
        }

        return this.step_map[name]
    }

    private onChangeList(type: "append" | "clear", name: string, idx?: number) {
        const step: obj[] = this.step_map[name]

        if (type === "append") {
            step.push({
                key_id: Utils.getUuid(),
            })
        } else {
            step.splice(idx, 1)
        }
    }

    getConditionValue(where: string) {
        if (!where) return true
        return new Function(`return ${where.replace(/&{(\w*)}/g, "this.$1")}`).call(this.value)
    }
}

export default Component.build(new FormView())
