
import ElemIcon from "@/components/elem-icon.vue"
import CompMenu from "@/components/comp-menu.vue"
import CompModal from "@/components/comp-modal.vue"
import ElemForm from "@/components/elem-form.vue"

import QRCode from "qrcodejs2"

import Cache from "@/module/cache/cache"
import Request from "@/module/request/request"
import Message from "@/module/interactive/message"
import Component, { ComponentMethods } from "@/module/component/component"
import DateUtils from "@/module/utils/date"

class ElemService extends ComponentMethods implements ComponentEntity {
    components = {
        ElemIcon,
        CompMenu,
        CompModal,
        ElemForm,
    }

    // 续费订单
    public renewOrder = null

    // 服务菜单
    public serviceMenu = null

    // 短信充值表单
    public sms_structure = [
        {
            field: "count",
            name: "count",
            title: "充值短信数",
            required: true,
            type: "Select",
            selectConfig: {
                data: [
                    {
                        id: "1",
                        value: "100条 - ￥10",
                    },
                    {
                        id: "2",
                        value: "500条 - ￥50",
                    },
                ],
            },
        },
    ]

    mounted() {
        Cache.onGet<obj>("userinfo", res => {
            this.serviceMenu = [
                {
                    title: "平台服务",
                    prompt: "当前平台服务有效期截止于：" + (res.company.validDate || "-"),
                    sub: [
                        {
                            id: "renew",
                            icon: "price",
                            name: "续费",
                            onClick: () => {
                                // 创建订单
                                this.createRenewOrder()
                                // 显示二维码页面
                                this.$refs.renew_modal.display()
                            },
                        },
                    ],
                },
                {
                    title: "短信服务",
                    prompt: "当前剩余短信条数： 0",
                    sub: [
                        {
                            id: "renew",
                            icon: "price",
                            name: "充值",
                            onClick: () => {
                                this.$refs.sms_recharge.display()
                            },
                        },
                        {
                            id: "renew",
                            icon: "price",
                            name: "充值记录",
                        },
                    ],
                },
            ]
        })
    }

    public createRenewOrder() {
        Request.get<obj>("WEB://PlatformCostOrder/CreateQrCodeOrder").then(res => {
            this.renewOrder = res
            this.drawQrCode(res.codeUrl)
            this.checkingRenew = true
            this.checkingRenewOrder()
        })
    }

    public checkingRenewOrder() {
        Request.get<number>("WEB://PlatformCostOrder/GetPaymentStatus", {
            uuid: this.renewOrder.orderId,
        }).then(res => {
            if (res === 1) {
                Message.success("续费成功")
                // 关闭二维码页面
                this.$refs.renew_modal.close()
                // 获取用户信息
                const userinfo = Cache.get<obj>("userinfo")
                // 获取当前企业有效期
                const date = new Date(userinfo.company.validDate)
                // 自增一年
                date.setFullYear(date.getFullYear() + 1)
                // 更新有效期
                userinfo.company.validDate = DateUtils.formatDate(date, "yyyy-MM-dd HH:mm:ss")
                // 更新缓存
                Cache.set("userinfo", userinfo)

                return this.$emit("on-success", {
                    tag: "ElemService",
                })
            }

            setTimeout(() => {
                if (this.checkingRenew) {
                    this.checkingRenewOrder()
                }
            }, 2000)
        })
    }

    /**
     * 绘制二维码
     */
    private drawQrCode(text: string) {
        // 创建一个二维码控件
        this.qrcode = new QRCode(this.$refs.qrcode, {
            text: text,
            colorDark: "#000000", // 前景色
            colorLight: "#ffffff", // 后景色
            correctLevel: QRCode.CorrectLevel.H, // 容错级别
        })
    }

    public onCloseRenewModal() {
        // 停止检查订单
        this.checkingRenew = false
        // 清除订单
        this.renewOrder = null

        const e: obj = this.$refs.qrcode

        for (let i = 0; i < e.childNodes.length; i++) {
            const item = e.childNodes[i]
            if (!item.className || item.className.indexOf("loading") === -1) {
                e.removeChild(item)
                i--
            }
        }
    }

    //  ---   短信充值   ---
    public async onSubmitSmsRecharge() {
        const data = await this.$refs.sms_form.getFormData()

        console.log(data)

        // Request.post("WEB://SmsOrder/CreateSmsOrder", {
        //     count: data.count,
        // }).then(res => {
        //     Message.success("充值成功")
        //     this.$refs.sms_recharge.close()
        // })
    }
}

export default Component.build(new ElemService())
