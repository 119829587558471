
import Utils from "@/module/utils/utils"

export default {
    data() {
        return {
            form_value: {},
        }
    },

    props: {
        name: String,
        data: Object,
        config: Object,
        entity: String,
        index: [Number, String],
        value: null,
        width: {
            type: [Number, String],
            required: false,
        },
        // 类型
        type: {
            type: String,
            default: "create",
        },
    },

    methods: {
        /**
         * 监听资源加载事件
         */
        onLoadResource(evt) {
            this.$emit("on-change", {
                tag: "elem-form-item",
                type: "upload",
                name: evt.name,
                value: evt.value,
            })
        },

        onChangeData(evt: ElemEvent<any>) {
            this.$emit("on-change", {
                tag: "elem-form-item",
                type: "value",
                name: evt.name,
                value: evt.value,
            })
        },

        getTypeName(type: string) {
            switch (type) {
                case "Select":
                    return "（选择）"
                case "Upload":
                    return "（上传文件）"
                case "Switch":
                    return "（开关）"
                default:
                    return ""
            }
        },

        getValue(field: string, name: string, idx: number, key?: string) {
            try {
                var value: any

                if (!name || name === "null") {
                    value = this.initial_value[field]
                } else if (this.initial_value[name] && !Utils.isBlank(idx)) {
                    value = this.initial_value[name][idx][field]
                } else {
                    value = this.initial_value[name][field]
                }

                if (key && Utils.isExist(value)) {
                    if (value instanceof Array) {
                        return Utils.each(value, v => v[key])
                    }

                    return value[key]
                }

                return value
            } catch (e) {
                return null
            }
        },

        getComponent(name: string): any {
            return require(`./${name}.vue`).default
        },
    },
}
