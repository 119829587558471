
import Component, { ComponentMethods } from '@/module/component/component'

class ElemPromptComponent extends ComponentMethods implements ComponentEntity {

    public display: boolean = false

    props = {
        title: String
    }

    mounted() {
        const dom: HTMLDivElement = this.$el

        const parent = this.parent = dom.parentElement

        if (!this.absolute) {
            parent.style.position = "relative"
        }

        parent.onmouseover = () => {
            this.display = true
        }

        parent.onclick = parent.onmouseout = () => {
            this.display = false
        }
    }
}

export default Component.build(new ElemPromptComponent)
