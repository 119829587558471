
import Component, { ComponentMethods } from '../module/component/component'

import elemIcon from './elem-icon.vue'

class EmptyComponent extends ComponentMethods implements ComponentEntity {
    props = {
        // 类型传参为 page 或 component , 默认 page
        type: {
            type: String,
            default: "page"
        },
        title: String,
        prompt: String
    }

    language = {
        page: "当前页面数据为空",
        components: "当前组件数据为空",
        examine: "请检查当前状态或与我们联系",
        back: "返回上一级"
    }

    components = {
        elemIcon
    }

    onBack() {
        let onUnload = this.$parent.onUnload
        onUnload && onUnload()

        this.$router.back(-1)
    }
}

export default Component.build(new EmptyComponent)
