
import Request from "@/module/request/request"
import Component, { ComponentMethods } from "../module/component/component"

import elemIcon from "./elem-icon.vue"

class EntityComponent extends ComponentMethods implements ComponentEntity {
    public fields: obj[] = null

    public record: obj = {}

    public show: boolean = false

    props = {
        config: Object,
        entity: String,
        title: {
            type: String,
            default: "实体",
        },
    }

    language = {}

    components = {
        elemIcon,
    }

    async getConfig() {
        await Request.get<obj[]>(`WEB://${this.entity}/GetEntityInfo`).then(res => {
            this.fields = res.sort(v1 => (v1.type === "Textarea" ? 1 : -1))
        })
    }

    async display(uuid: string): Promise<void> {
        if (!this.entity) {
            throw new Error("entity is null")
        }

        if (!this.fields) {
            await this.getConfig()
        }

        Request.get<obj>(`WEB://${this.entity}/FindProcessEntity`, { uuid }).then(res => {
            this.record = res
            this.show = true
        })
    }

    getFiles(value: Object | Array<Object> | null) {
        if (!value) {
            return []
        }

        if (Array.isArray(value)) {
            return value
        }

        return [value]
    }
}

export default Component.build(new EntityComponent())
