
import Component, { ComponentMethods } from "@/module/component/component"

import Package from "@/../package.json"
import Queue from "@/module/queue/queue"
import Request from "@/module/request/request"
import Cache from "@/module/cache/cache"
import Utils from "@/module/utils/utils"
import Message from "@/module/interactive/message"

import elemInput from "@/components/elem-input.vue"
import elemIcon from "@/components/elem-icon.vue"
import compMenu from "@/components/comp-menu.vue"
import elemVerification from "@/components/elem-verification.vue"
import elemMenuBar from "@/components/elem-menu-bar.vue"

const md5 = require("js-md5")
import QRCode from "qrcodejs2"

class LoginPage extends ComponentMethods implements ComponentEntity {
    components = {
        elemInput,
        elemIcon,
        compMenu,
        elemVerification,
        elemMenuBar,
    }

    public login_type: "password" | "code" = "password"

    private input_focus: obj = {}

    private input_step: string = "username"

    private internal = {
        steps: [
            {
                name: "username",
                submit: function () {
                    const account = this.$refs.username.getValue()

                    if (!account) {
                        return Message.error("请输入账号")
                    }

                    if (this.login_type === "password") {
                        const password = this.$refs.password.getValue()

                        if (!password) {
                            return Message.error("请输入密码")
                        }

                        return this.onSubmit({
                            account: account,
                            password: md5(password),
                        })
                    }

                    const code = this.$refs.login_code.getValue()

                    if (!code?.text) {
                        return Message.error("请输入验证码")
                    }

                    const formdata = {
                        phone: account,
                        code: code.text,
                        validate: code.validate,
                    }

                    Request.post<obj>("WEB://Employee/SendLoginCode", formdata)
                        .then(res => {
                            // 缓存 Token
                            this.login_verify = {
                                token: res.token,
                                validate: res.id,
                                formdata: formdata,
                            }
                            // 切换到下一步
                            this.input_step = "verify_phone"
                        })
                        .catch(() => {
                            // 刷新验证码
                            this.$refs.login_code.refresh()
                        })
                },
            },
            {
                name: "verify_phone",
                submit: function () {
                    const d: obj = this.login_verify

                    if (!d.code?.text) {
                        return Message.error("请输入验证码")
                    }

                    this.onSubmit({
                        account: d.formdata.phone,
                        code: d.code.text,
                        validate: d.code.validate,
                    })
                },
            },
            {
                name: "forgot_username",
                ref: "forgot_username_input",
                prompt: "请输入需要找回密码的用户名",
                submit: function () {
                    this.onSubmitForgotUsername()
                },
            },
            {
                name: "forgot_email",
                ref: "forgot_email_input",
                prompt: function () {
                    return "请输入电子邮箱：" + this.userinfo.email
                },
                submit: function () {
                    this.onSubmitForgotEmail()
                },
            },
            {
                name: "forgot_code",
                ref: "forgot_code_input",
                prompt: "请输入电子邮箱验证码",
                submit: function () {
                    this.onSubmitForgotCode()
                },
            },
            {
                name: "forgot_password",
                ref: "forgot_password_input",
                prompt: "请输入新密码",
                submit: function () {
                    this.onSubmitForgotPassword()
                },
            },
            {
                name: "forgot_confirm_password",
                ref: "forgot_confirm_password_input",
                prompt: "请再次输入新密码",
                submit: function () {
                    this.onSubmitForgotConfirmPassword()
                },
            },
            {
                name: "enroll",
                submit: function () {
                    const formdata: obj = {}

                    for (let i = 0, l = [this.$refs.enroll_company_name, this.$refs.enroll_phone, this.$refs.enroll_code]; i < l.length; i++) {
                        let e = l[i]
                        let v = e.getValue()

                        if (!v) {
                            return Message.error("请输入：" + e.title)
                        }

                        formdata[e.name] = v
                    }

                    Request.post<obj>("WEB://Employee/SendRegisterCode", {
                        phone: formdata.phone,
                        code: formdata.code.text,
                        validate: formdata.code.validate,
                    })
                        .then(res => {
                            // 缓存 Token
                            this.enroll_verify.token = res.token
                            // 缓存验证码 ID
                            this.enroll_verify.validate = res.id
                            // 缓存数据
                            this.enroll_verify.formdata = formdata
                            // 切换到下一步
                            this.input_step = "enroll_verify_phone"
                        })
                        .catch(() => {
                            // 刷新验证码
                            this.$refs.enroll_code.refresh()
                        })
                },
            },
            {
                name: "enroll_verify_phone",
                submit: function () {
                    const d: obj = this.enroll_verify

                    Request.post<obj>("WEB://Employee/Register", {
                        phone: d.formdata.phone,
                        company_name: d.formdata.company_name,
                        code: d.code.text,
                        validate: d.code.validate,
                    }).then(res => {
                        Cache.set(
                            {
                                token: res.token,
                                user_id: res.id,
                                userinfo: res.userinfo,
                            },
                            { storage: "cookie", expires: 2 * 60 * 60 * 1000 }
                        )

                        // 刷新页面
                        Utils.each<Function>(this.callback, v => {
                            v()
                            // 触发事件后移除
                            return "delete"
                        })

                        this.onChangeDisplay(false)
                    })
                },
            },
        ],
    }

    private callback: (() => void)[] = []

    private codeInfo: obj = null

    private info: obj = {
        icon: "logo.png",
        name: "晟浩科技",
    }

    private loading: boolean = false

    private username: string = null
    private username_error: string = null

    private password: string = null
    private password_error: string = null
    private isPasswordType: boolean = true

    private code: string = null
    private code_error: string = null

    private forgot_username: string = null
    private forgot_username_error: string = null

    private forgot_email: string = null
    private forgot_email_error: string = null

    private forgot_code: string = null
    private forgot_code_error: string = null
    private forgot_code_time: string = "59"
    private forgot_code_id: string

    private forgot_password: string = null
    private forgot_password_error: string = null

    private forgot_confirm_password: string = null
    private forgot_confirm_password_error: string = null

    private auto_login: boolean = false

    private userinfo: obj = {
        avatar: "logo.png",
        username: "admin",
    }

    private setting_menu = null

    private prompt: string = null

    // 注册校验数据
    public enroll_verify = {
        formdata: {},
        token: null,
    }

    // 登录校验数据
    public login_verify = {
        formdata: {},
        token: null,
    }

    // 价格
    public price: string = "0.00"

    data() {
        return {
            display: false,
            show: false,
            version: Package.version,
        }
    }

    watch = {
        username() {
            if (this.username_error) {
                this.username_error = null
            }
        },

        input_step(value: string) {
            Utils.find<obj>(
                this.internal.steps,
                c => c.name == value,
                v => {
                    const data = v.data

                    this[value] = null
                    this.prompt = typeof data.prompt === "function" ? data.prompt.call(this) : data.prompt

                    // 延时 300ms，等待动画结束
                    Utils.wait(() => {
                        // 触发焦点事件
                        this.$refs[data.ref]?.focus()
                    }, 500)
                }
            )
        },
    }

    mounted() {
        this.createOrder()
    }

    public openService() {
        this.display = true
        this.createOrder()
    }

    public createOrder() {
        Request.get<obj>("WEB://PlatformCostOrder/CreateQrCodeOrder").then(res => {
            this.price = res.price.toFixed(2)
            this.drawQrCode(res.codeUrl)
            this.checking = true
            this.checkingOrder()
        })
    }

    public checkingOrder() {
        Request.get<obj>("WEB://Company/IsHaveOpened").then(res => {
            if (res) {
                Message.success("开通成功")
                return this.$emit("on-success", {
                    tag: "Steer",
                })
            }

            setTimeout(() => {
                if (this.checking) {
                    this.checkingOrder()
                }
            }, 2000)
        })
    }

    /**
     * 绘制二维码
     */
    private drawQrCode(text: string) {
        // 创建一个二维码控件
        this.qrcode = new QRCode(this.$refs.qrcode, {
            text: text,
            width: 1000, // 高度
            height: 1000, // 宽度
            colorDark: "#000000", // 前景色
            colorLight: "#ffffff", // 后景色
            correctLevel: QRCode.CorrectLevel.H, // 容错级别
        })
    }

    public onBackStep() {
        if (this.input_step === "forgot_username") {
            this.input_step = "username"
        } else {
            Utils.find<obj>(
                this.internal.steps,
                c => c.name === this.input_step,
                v => {
                    this.input_step = this.internal.steps[v.i - 1].name
                }
            )
        }
    }

    /** 获取验证码数据 */
    public getValidateCode() {
        Queue.single("Login-GetValidateCode", next => {
            this.codeInfo = null

            Request.get<obj>("WEB://ValidateCode/GetCode", null, {
                hideLoading: true,
                onComplete: () => {
                    next()
                },
            }).then(res => {
                this.codeInfo = res
            })
        })
    }

    public async onSubmit(data) {
        Request.post<obj>("WEB://Employee/Login", data).then(res => {
            Cache.set(
                {
                    token: res.token,
                    user_id: res.id,
                    userinfo: res.userinfo,
                },
                { storage: "cookie", expires: 2 * 60 * 60 * 1000 }
            )

            // 刷新页面
            Utils.each(this.callback, v => {
                v()
                // 触发事件后移除
                return "delete"
            })

            this.onChangeDisplay(false)
        })
    }

    onStartForgotCodeTime() {
        var start: number = 59

        clearInterval(this.forgot_code_time_interval)

        this.forgot_code_time_interval = setInterval(() => {
            this.forgot_code_time = Utils.paddingZero(--start)

            if (start <= 0) {
                clearInterval(this.forgot_code_time_interval)
            }
        }, 1000)
    }

    onChangeFocusInput(name: string, focus: boolean): void {
        this.input_focus[name] = focus
    }

    getAccountInfo(): void {
        this.loading = true

        Utils.wait(() => {
            Request.get(
                "WEB://Employee/GetAccountInfo",
                { username: this.username },
                {
                    onFail: err => {
                        if (err.status === 400 && err.code === "NOT_USERNAME") {
                            this.username_error = "账号不存在"
                            return false
                        }
                    },
                    onComplete: () => {
                        this.loading = false
                    },
                }
            ).then(res => {
                this.userinfo = res
                this.input_step = "password"
            })
        }, 2000)
    }

    onNextStep() {
        Utils.find<obj>(
            this.internal.steps,
            c => c.name == this.input_step,
            v => {
                const data = v.data
                // 触发提交事件
                data.submit.call(this)
            }
        )
    }

    onChangePasswordType() {
        this.isPasswordType = !this.isPasswordType
    }

    onSubmitInput(evt: obj) {
        const e: HTMLInputElement = evt.target
        // 触发失焦事件
        e.blur()
        // 下一步
        this.onNextStep()
    }

    getStepTitle() {
        switch (this.input_step) {
            case "forgot_username":
            case "forgot_password":
            case "username":
                return this.login_type === "password" ? "登录" : "获取验证码"
            case "verify_phone":
                return "登录"
            case "forgot_code":
            case "code":
                return "验证"
            case "forgot_email":
                return "获取验证码"
            case "enroll":
                return "获取手机验证码"
            case "enroll_verify_phone":
                return "提交注册"
            default:
                return "提交"
        }
    }

    isForgot() {
        const step = this.input_step
        return step.indexOf("forgot") > -1
    }

    onDisplay() {
        // this.input_step = "username"
        this.onChangeDisplay(true)
    }

    onChangeDisplay(display: boolean) {
        if (display) {
            this.show = true

            Utils.wait(() => {
                this.display = true
            })
        } else {
            this.display = false

            Utils.wait(() => {
                this.show = false
            }, 500)
        }
    }

    /**
     * 提交找回密码用户名
     */
    onSubmitForgotUsername() {
        if (!this.forgot_username) {
            return (this.forgot_username_error = "用户名不能为空")
        }

        this.loading = true

        Utils.wait(() => {
            Request.get(
                "WEB://AdminAccount/GetForgotAccountInfo",
                { username: this.forgot_username },
                {
                    onFail: err => {
                        if (err.status === 400) {
                            switch (err.code) {
                                case "NOT_USERNAME":
                                    this.forgot_username_error = "账号不存在"
                                    return false
                                case "NO_EMAIL":
                                    this.forgot_username_error = "未绑定电子邮箱"
                                    Message.error("当前用户未绑定电子邮箱，请联系技术支持！")
                                    return false
                            }
                        }
                    },
                    onComplete: () => {
                        this.loading = false
                    },
                }
            ).then(res => {
                this.userinfo = res
                this.input_step = "forgot_email"
            })
        }, 2000)
    }

    onSubmitForgotEmail() {
        if (!this.forgot_email) {
            return (this.forgot_email_error = "电子邮箱不能为空")
        }

        Request.post<string>("WEB://AdminAccount/SendEmailCode", {
            username: this.forgot_username,
            email: this.forgot_email,
        }).then(res => {
            this.forgot_code_id = res
            this.input_step = "forgot_code"
            this.onStartForgotCodeTime()
        })
    }

    onSubmitForgotPassword() {
        if (!new RegExp("(?=.*[0-9])(?=.*[a-zA-Z]).{6,}").test(this.forgot_password)) {
            return (this.forgot_password_error = "密码中必须包含字母和数字且至少6个字符")
        }

        this.input_step = "forgot_confirm_password"
    }

    onSubmitForgotConfirmPassword() {
        if (this.forgot_password !== this.forgot_confirm_password) {
            return (this.forgot_confirm_password_error = "两次密码不相同，请检查后重试")
        }

        Request.post("WEB://AdminAccount/ResetPassword", {
            username: this.forgot_username,
            email: this.forgot_email,
            codeId: this.forgot_code_id,
            code: this.forgot_code,
            password: md5(this.forgot_password),
        }).then(() => {
            Message.success("重置密码成功")
            this.input_step = "username"
        })
    }

    onSubmitForgotCode() {
        if (!this.forgot_code) {
            return (this.forgot_code_error = "验证码不能为空")
        }

        Request.post<string>("WEB://AdminAccount/VerifyEmailCode", {
            username: this.forgot_username,
            email: this.forgot_email,
            codeId: this.forgot_code_id,
            code: this.forgot_code,
        }).then(() => {
            this.input_step = "forgot_password"
        })
    }

    onSendForgotCode() {
        Request.post<string>("WEB://AdminAccount/SendEmailCode", {
            username: this.forgot_username,
            email: this.forgot_email,
        }).then(res => {
            this.forgot_code_id = res
            this.onStartForgotCodeTime()
        })
    }

    onChangeInput(name: string) {
        this[name] = null
    }
}

export default Component.build(new LoginPage())
