import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "elem-editor-box",
  "dark-class": "elem-editor-box-dark"
}
const _hoisted_2 = ["id", "required", "name", "title", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      class: "editor",
      id: _ctx.id,
      required: _ctx.required,
      name: _ctx.name,
      title: _ctx.title,
      placeholder: _ctx.placeholder,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.val) = $event)),
      onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.val]
    ])
  ]))
}