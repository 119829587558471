import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_form = _resolveComponent("elem-form")!
  const _component_CompModal = _resolveComponent("CompModal")!

  return (_openBlock(), _createBlock(_component_CompModal, {
    title: "企业微信聊天机器人配置",
    ref: "robot_modal",
    width: "400px",
    "on-success": $options.onSubmitRobot,
    onOnClose: $options.onCloseRobotModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_elem_form, {
        ref: "robot_form",
        value: $data.robot_value,
        structure: $data.robot_structure
      }, null, 8, ["value", "structure"])
    ]),
    _: 1
  }, 8, ["on-success", "onOnClose"]))
}