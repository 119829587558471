
import elemOptions from "./elem-options.vue"
import elemIcon from "./elem-icon.vue"

import Utils from "@/module/utils/utils"
import Version from "@/module/version/version"

export default {
    components: {
        elemOptions,
        elemIcon,
    },

    data() {
        return {
            values: [],
            titles: {},
            date_open: false,
            dates: null,
        }
    },

    props: {
        filters: Object,
    },

    methods: {
        check: Version.checkFrameworkVersion.bind(Version),

        /**
         * 监听过滤事件
         */
        onFilter(field: string, value: obj, operator = "equal"): void {
            const _filter: obj[] = this.values

            if (Utils.isExist(value.value)) {
                let f = Utils.find<obj>(_filter, c => c.key === field)
                let item = {
                    key: field,
                    value: value.value,
                    operator: operator,
                }

                if (f.i > -1) {
                    _filter[f.i] = item
                } else {
                    _filter.push(item)
                }

                this.titles[field] = value.title
            } else {
                // 删除过滤条件
                Utils.each(
                    _filter,
                    () => "delete",
                    c => c.key === field
                )
                // 移除标题
                delete this.titles[field]
            }

            this.$emit("on-change", {
                tag: "elem-filter",
                value: _filter,
            })
        },

        onChangeDate(evt: string[]) {
            // 删除过滤条件
            Utils.each<obj>(
                this.values,
                () => "delete",
                c => c.key === "createdDate"
            )

            if (evt?.[0] && evt?.[1]) {
                if (evt[0] === evt[1])
                    this.values.push({
                        key: "createdDate",
                        value: evt[0],
                        type: "date",
                        operator: "equal",
                    })
                else
                    this.values.push(
                        {
                            key: "createdDate",
                            value: evt[0],
                            type: "date",
                            operator: "greater_equal",
                        },
                        {
                            key: "createdDate",
                            value: evt[1],
                            type: "date",
                            operator: "less_equal",
                        }
                    )

                this.dates = evt
            } else {
                this.dates = null
            }

            // 设置为关闭状态
            this.date_open = false

            this.$emit("on-change", {
                tag: "elem-filter",
                value: this.values,
            })
        },
    },
}
