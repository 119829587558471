import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "title"
}
const _hoisted_2 = {
  key: 1,
  class: "prompt"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "info-box" }
const _hoisted_5 = {
  key: 0,
  class: "icon-box"
}
const _hoisted_6 = { class: "name" }
const _hoisted_7 = {
  key: 0,
  class: "value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_elem_icon = _resolveComponent("elem-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["comp-menu", { 'comp-menu-dark': _ctx.isDark(), 'comp-menu-display': _ctx.display }]),
    style: _normalizeStyle({ 'max-width': _ctx.maxWidth, width: _ctx.width })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (item, idx) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "item-box",
        key: idx
      }, [
        (item.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(item.title), 1))
          : _createCommentVNode("", true),
        (item.prompt)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(item.prompt), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.sub, (sub, sub_idx) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["sub-item-box", { disable: sub.disable }]),
            key: sub_idx,
            onClick: _withModifiers(($event: any) => (_ctx.onSelectMenu(sub)), ["stop"])
          }, [
            _createElementVNode("div", _hoisted_4, [
              (sub.icon)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    (_openBlock(), _createBlock(_component_elem_icon, {
                      name: sub.icon,
                      key: sub.id + sub.icon
                    }, null, 8, ["name"]))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("p", _hoisted_6, _toDisplayString(sub.name), 1)
            ]),
            (sub.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(sub.value), 1))
              : _createCommentVNode("", true)
          ], 10, _hoisted_3))
        }), 128))
      ]))
    }), 128))
  ], 6))
}