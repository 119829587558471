import { h } from "vue"

export default {
    name: "TableRenderHeader",
    functional: true,
    props: {
        render: Function,
        row: Object,
        index: Number,
    },
    render: function () {
        const params = {
            row: this.row,
            index: this.index,
        }

        const cb = this.render(h, params)

        return typeof cb === "object" ? cb : h("div", cb)
    },
}
