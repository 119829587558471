
import Component, { ComponentMethods } from "@/module/component/component"

class ElemTextareaComponent extends ComponentMethods implements ComponentEntity {
    data() {
        return {
            place_holder: null,
            original: null,
            check: null,
            val: null,
        }
    }

    props = {
        type: String,
        name: String,
        title: String,
        required: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
    }

    watch = {
        value(val: string) {
            this.val = val
        },
    }

    mounted() {
        if (!this.placeholder) {
            this.place_holder = `输入${this.title || this.name}${this.required ? "" : "（可选）"}`
        }
    }

    methods = {
        onChange(evt) {
            this.$emit("change", {
                value: evt.target.value,
                type: "elem-textarea",
                name: this.name,
                set: (value: string) => {
                    this.val = value
                },
            })
        },

        onInput(evt) {
            this.$emit("input-event", {
                value: evt.target.value,
                type: "elem-textarea",
                name: this.name,
                set: (value: string) => {
                    this.val = value
                },
            })
        },
    }
}

export default Component.build(new ElemTextareaComponent())
