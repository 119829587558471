import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "options-base" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["elem-options-box", { 'elem-options-activity': $data.focus, 'elem-options-box-bottom': $props.direction === 'bottom', 'elem-options-box-top': $props.direction !== 'bottom' }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      ($props.all)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: "item",
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($options.onSelect()))
          }, "所有"))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.data, (item, idx) => {
        return (_openBlock(), _createElementBlock("p", {
          class: "item",
          key: idx,
          onClick: ($event: any) => ($options.onSelect(item, idx))
        }, _toDisplayString(item.title || item), 9, _hoisted_2))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "arrow-box",
      style: _normalizeStyle({ left: $data.arrow_left })
    }, null, 4)
  ], 2))
}