import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_index_view = _resolveComponent("index-view")!
  const _component_steer_view = _resolveComponent("steer-view")!
  const _component_login_view = _resolveComponent("login-view")!
  const _component_comp_menu = _resolveComponent("comp-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($data.acPage === 'index')
      ? (_openBlock(), _createBlock(_component_index_view, { key: 0 }))
      : _createCommentVNode("", true),
    ($data.acPage === 'steer')
      ? (_openBlock(), _createBlock(_component_steer_view, {
          key: 1,
          onOnSuccess: $options.onSuccessSteer
        }, null, 8, ["onOnSuccess"]))
      : _createCommentVNode("", true),
    _createVNode(_component_login_view, { ref: "login" }, null, 512),
    _createVNode(_component_comp_menu, {
      position: "follow",
      "click-type": "right",
      cursor: "initial",
      value: $data.setting_menu
    }, null, 8, ["value"])
  ], 64))
}