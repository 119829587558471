import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = {
  key: 0,
  class: "input-box"
}
const _hoisted_3 = {
  key: 1,
  class: "input-box"
}
const _hoisted_4 = {
  key: 2,
  class: "input-box"
}
const _hoisted_5 = {
  key: 3,
  class: "input-box"
}
const _hoisted_6 = {
  key: 4,
  class: "input-box"
}
const _hoisted_7 = {
  key: 5,
  class: "textarea-box"
}
const _hoisted_8 = {
  key: 6,
  class: "switch-box"
}
const _hoisted_9 = {
  key: 7,
  class: "select-box"
}
const _hoisted_10 = {
  key: 8,
  class: "upload-box"
}
const _hoisted_11 = {
  key: 9,
  class: "editor-box"
}
const _hoisted_12 = {
  key: 10,
  class: "date-box"
}
const _hoisted_13 = {
  key: 11,
  class: "map-box"
}
const _hoisted_14 = {
  key: 12,
  class: "label-box"
}
const _hoisted_15 = {
  key: 13,
  class: "richtext-box"
}
const _hoisted_16 = {
  key: 14,
  class: "keyboard-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["comp-form-item-box", 'item-' + $props.data.type]),
    style: _normalizeStyle($props.width ? { width: $props.width } : '')
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString($props.data.title) + _toDisplayString($options.getTypeName($props.data.type)), 1),
    ($props.data.type === 'Input')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-input')), {
            title: $props.data.title,
            value: $props.value,
            field: $props.data.field,
            type: "text",
            name: $props.name,
            placeholder: $props.data.placeholder,
            required: $props.data.required,
            onOnChange: $options.onChangeData
          }, null, 40, ["title", "value", "field", "name", "placeholder", "required", "onOnChange"]))
        ]))
      : ($props.data.type === 'Number')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-input')), {
              title: $props.data.title,
              value: $props.value,
              field: $props.data.field,
              type: "number",
              name: $props.name,
              placeholder: $props.data.placeholder,
              required: $props.data.required,
              onOnChange: $options.onChangeData
            }, null, 40, ["title", "value", "field", "name", "placeholder", "required", "onOnChange"]))
          ]))
        : ($props.data.type === 'Phone')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-input')), {
                title: $props.data.title,
                value: $props.value,
                field: $props.data.field,
                type: "number",
                name: $props.name,
                placeholder: $props.data.placeholder,
                required: $props.data.required,
                onChange: $options.onChangeData,
                verify: "phone"
              }, null, 40, ["title", "value", "field", "name", "placeholder", "required", "onChange"]))
            ]))
          : ($props.data.type === 'Price')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-price')), {
                  title: $props.data.title,
                  value: $props.value,
                  field: $props.data.field,
                  name: $props.name,
                  placeholder: $props.data.placeholder,
                  required: $props.data.required,
                  onChange: $options.onChangeData
                }, null, 40, ["title", "value", "field", "name", "placeholder", "required", "onChange"]))
              ]))
            : ($props.data.type === 'Password')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-password')), {
                    title: $props.data.title,
                    value: $props.value,
                    field: $props.data.field,
                    config: $props.data.passwordConfig,
                    verify: $props.data.passwordConfig?.verify,
                    name: $props.name,
                    placeholder: $props.data.placeholder,
                    required: $props.data.required,
                    onChange: $options.onChangeData
                  }, null, 40, ["title", "value", "field", "config", "verify", "name", "placeholder", "required", "onChange"]))
                ]))
              : ($props.data.type === 'Textarea')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-textarea')), {
                      name: $props.name,
                      title: $props.data.title,
                      placeholder: $props.data.placeholder,
                      required: $props.data.required,
                      onChange: $options.onChangeData,
                      value: $props.value
                    }, null, 40, ["name", "title", "placeholder", "required", "onChange", "value"]))
                  ]))
                : ($props.data.type === 'Switch')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-switch')), {
                        name: $props.name,
                        onChange: $options.onChangeData,
                        field: $props.data.field,
                        value: $props.value,
                        shape: "square"
                      }, null, 40, ["name", "onChange", "field", "value"]))
                    ]))
                  : ($props.data.type === 'Select')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-select')), {
                          name: $props.name,
                          onChange: $options.onChangeData,
                          datas: $props.data.selectConfig.data,
                          field: $props.data.field,
                          multiple: $props.data.selectConfig.multiple,
                          required: $props.data.required,
                          title: $props.data.title,
                          placeholder: $props.data.placeholder,
                          value: $props.value,
                          disabled: $props.type === 'edit' && !$props.data.modify
                        }, null, 40, ["name", "onChange", "datas", "field", "multiple", "required", "title", "placeholder", "value", "disabled"]))
                      ]))
                    : ($props.data.type === 'Upload')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-upload')), {
                            name: $props.name,
                            value: $props.value,
                            field: $props.data.field,
                            title: $props.data.title,
                            required: $props.data.required,
                            type: $props.data.uploadConfig.type,
                            belong: $props.data.uploadConfig.belong,
                            accept: $props.data.uploadConfig.accept,
                            multi: $props.data.uploadConfig.multi,
                            onChange: $options.onChangeData,
                            onOnLoad: $options.onLoadResource
                          }, null, 40, ["name", "value", "field", "title", "required", "type", "belong", "accept", "multi", "onChange", "onOnLoad"]))
                        ]))
                      : ($props.data.type === 'Editor')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-editor')), {
                              name: $props.name,
                              required: $props.data.required,
                              title: $props.data.title,
                              field: $props.data.field,
                              placeholder: $props.data.placeholder,
                              value: $props.value
                            }, null, 8, ["name", "required", "title", "field", "placeholder", "value"]))
                          ]))
                        : ($props.data.type === 'Date')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-date')), {
                                name: $props.name,
                                required: $props.data.required,
                                value: $props.value
                              }, null, 8, ["name", "required", "value"]))
                            ]))
                          : ($props.data.type === 'Map')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-map')), {
                                  name: $props.name,
                                  required: $props.data.required,
                                  value: $props.value
                                }, null, 8, ["name", "required", "value"]))
                              ]))
                            : ($props.data.type === 'Label')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-label')), {
                                    name: $props.name,
                                    type: $props.data.labelConfig ? $props.data.labelConfig.type : 'input',
                                    required: $props.data.required,
                                    field: $props.data.field,
                                    title: $props.data.title,
                                    placeholder: $props.data.placeholder,
                                    value: $props.value
                                  }, null, 8, ["name", "type", "required", "field", "title", "placeholder", "value"]))
                                ]))
                              : ($props.data.type === 'RichText')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                                    (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-richtext')), {
                                      name: $props.name,
                                      required: $props.data.required,
                                      field: $props.data.field,
                                      value: $props.value
                                    }, null, 8, ["name", "required", "field", "value"]))
                                  ]))
                                : ($props.data.type === 'Keyboard')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                      (_openBlock(), _createBlock(_resolveDynamicComponent($options.getComponent('elem-keyboard')), {
                                        name: $props.name,
                                        required: $props.data.required,
                                        field: $props.data.field,
                                        value: $props.value
                                      }, null, 8, ["name", "required", "field", "value"]))
                                    ]))
                                  : _createCommentVNode("", true)
  ], 6))
}