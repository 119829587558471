
import Request from "@/module/request/request"
import ElemTable from "./elem-table.vue"

import Message from "@/module/interactive/message"

export default {
    components: { ElemTable },

    data() {
        return {
            isSearch: false,
            // 加载完毕
            loaded: false,
            search_data: {},
            // 错误消息
            error_msg: "",
            // 插槽
            slots: [],
        }
    },

    props: {
        // 标题
        title: String,
        // 表格配置
        columns: Array,
        // 表格接口地址
        tableApi: String,
        // 查询参数
        searchData: {
            type: Object,
            default: () => ({}),
        },
        // 是否开启搜索按钮
        searchBtn: {
            type: Boolean,
            default: true,
        },
        // 删除配置
        deleteApi: {
            type: [Function, String],
            required: false,
        },
        // 是否可创建
        isCreate: {
            type: Boolean,
            default: true,
        },
        // 编辑对应的 ID Key
        editKey: {
            type: String,
            required: false,
        },
        // 表格操作栏配置
        operate: {
            type: Function,
            required: false,
        },
        // 表单组件
        formComponent: {
            type: Object,
            required: false,
        },
        // 数据前置事件
        dataBefore: {
            type: Function,
            required: false,
        },
        // 修改按钮名称
        editButton: {
            type: String,
            default: "修改",
        },
        // 分页参数 key 值名称
        keys: {
            type: Object,
            default: new Object(null),
        },
        // 是否开启选择器功能
        isSelector: {
            type: Boolean,
            default: false,
        },
        // 显示合计
        summary: {
            type: Boolean,
            default: false,
        },
        // 请求方法
        method: {
            type: String,
            default: "get",
        },
        // 处理分页配置
        processPageConfig: {
            type: Function,
            required: false,
        },
        // 接口数据
        data: null,
        // 数据处理
        processData: {
            type: Function,
            required: false,
        },
        // 错误提示
        error: {
            type: String,
            required: false,
        },
        // 数据列表
        dataList: {
            type: Array,
            required: false,
        },
        entity: {
            type: String,
            required: false,
        },
    },

    watch: {
        search_data: {
            handler(v) {
                const o = this.old_data
                for (let i = 0, ks = Object.keys(v); i < ks.length; i++) {
                    let k = ks[i]
                    if (v[k] !== o?.[k]) {
                        this.$emit("on-change-search", {
                            tag: "CompTable",
                            value: {
                                key: k,
                                value: v[k],
                            },
                        })
                    }
                }
                this.old_data = Object.assign({}, v)
            },
            deep: true,
        },
        searchData: {
            handler(val) {
                this.search_data = Object.assign({}, val)
                this.cache_data = JSON.parse(JSON.stringify(val))
            },
            immediate: true,
        },
        error: {
            handler(val) {
                this.error_msg = val
            },
            immediate: true,
        },
        columns: {
            handler(val) {
                this.slots = val ? val.filter(i => i.slot).map(i => i.slot) : []
            },
            immediate: true,
        },
    },

    mounted() {
        // 是否存在表单组件，存在即开启搜索功能
        this.isSearch = this.$refs.search_form.$el?.children?.length > 0

        setTimeout(() => {
            this.loaded = true
        })
    },

    methods: {
        /**
         * 详情
         */
        detail(uuid: string) {
            this.$refs.elem_table.detail(uuid)
        },

        search(k, v: any, l) {
            if (typeof k === "object") {
                for (let i = 0, ks = Object.keys(k); i < ks.length; i++) {
                    // 写入数据
                    this.search_data[ks[i]] = k[ks[i]]
                }
                // 触发搜索
                if (v !== false) this.onSearch()
                // 结束
                return
            }
            // 写入数据
            this.search_data[k] = v
            // 触发搜索
            if (l !== false) this.onSearch()
        },

        /**
         * 搜索查询
         */
        async onSearch() {
            var data = JSON.parse(JSON.stringify(this.search_data))

            if (this.dataBefore) {
                var cb = this.dataBefore(data)

                if (cb instanceof Promise) {
                    cb = await cb
                }

                // 无返回值时
                cb = cb ?? data

                if (cb === false) return

                data = cb
            }

            this.$refs.elem_table.search(data)
        },

        /**
         * 重置
         */
        onReset() {
            const d = this.search_data
            // 重置数据
            for (let i = 0, ks = Object.keys(d); i < ks.length; i++) {
                let k = ks[i]
                if (typeof this.cache_data[k] !== "undefined") {
                    d[k] = typeof this.cache_data[k] === "object" ? JSON.parse(JSON.stringify(this.cache_data[k])) : this.cache_data[k]
                } else d[k] = null
            }
            // 重置表格组件
            this.$refs.elem_table?.reset()
            // 触发事件
            this.$emit("on-reset")
        },

        /**
         * 重置
         */
        reset() {
            this.onReset()
        },

        /**
         * 刷新
         */
        refresh() {
            this.$refs.elem_table?.refresh?.()
        },

        /**
         * 刷新
         */
        onRefresh() {
            this.$refs.elem_table.refresh()
        },

        setSearchData(key, value) {
            this.search_data[key] = value
        },

        /**
         * 显示表单页面，ID 可选，传 ID 为编辑模式
         */
        openForm(id?: string, data?: obj) {
            this.$refs.elem_table.openForm(id, data)
        },

        onChangeSelection(evt) {
            this.selection = evt.value
        },

        getSelection() {
            return this.selection
        },

        export() {
            this.$refs.elem_table.export()
        },

        resize() {
            this.$refs.elem_table.resize()
        },

        /**
         * 清空勾选
         */
        clearSelecteds() {
            this.$refs.elem_table.clearSelecteds()
        },

        keyword(t) {
            this.$refs.elem_table.keyword(t)
        },

        /**
         * 删除数据
         */
        onDelete(data: obj) {
            Message.info("是否确认删除当前数据？", false)
                .onConfirm(() => {
                    Request.delete(this.deleteApi, data).then(() => {
                        this.$refs.elem_table.refresh()
                    })
                })
                .build()
        },
    },
}
